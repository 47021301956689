import React, { useState } from 'react';
import cn from 'classnames';

import styles from './SubjectTransferNotes.module.scss';
import { Button, Drawer } from 'antd';

const SubjectTransferNotes = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="link" onClick={showDrawer}>Что это?</Button>
      <Drawer title="Справка" onClose={onClose} open={open} size="large">
        <p>
          Здесь Вы можете настроить связь между предметами в оффлайн системе и предметами в онлайн платформе.
        </p>
        <p>
          Вам нужно указать "Предметную группу на платформе", "Курс на платформе", "Предмет в оффлайн CRM", "Язык на котором учится студент оффлайн"
        </p>
        <p>
          Примеры:<br/>
          <b><i>Пользователь учится на предмете "История" на русском языке в оффлайне. А на онлайн платформе ему нужно
          открыть доступ до двух курсов - "История Узбекистана" и "Всемирная история"</i></b>
        </p>
        <p>
          Тогда Вам нужно создать две связи между предметами вручную:
          <ol>
            <li>
              Предметная группа на платформе - <b>История Узбекистана</b> <br/>
              Курс на платформе - <b>История Узбекистана (На русском)</b> <br/>
              Предмет в оффлайн CRM системе - <b>История</b> <br/>
              Язык предмета в оффлайн CRM системе - <b>Русский</b> <br/>
            </li>
            <li>
              Предметная группа на платформе - <b>Всемирная история</b> <br/>
              Курс на платформе - <b>Всемирная история (На русском)</b> <br/>
              Предмет в оффлайн CRM системе - <b>История</b> <br/>
              Язык предмета в оффлайн CRM системе - <b>Русский</b> <br/>
            </li>
          </ol>
        </p>
        <p>
          В этом случае, онлайн-платформа определит что для Предмета "История" нужно открыть две подписки на два курса.
        </p>
        <p>
          <b><i>Пользователь учится на предмете "Английский язык" на русском языке в оффлайне. На онлайн платформе Вам нужно
            открыть доступ для всех курсов, которые входят в предметную группу "Английский язык"</i></b>
        </p>
        <p>
          Тогда Вам нужно создать одну связь между предметами вручную:
          <ol>
            <li>
              Предметная группа на платформе - <b>Английский язык</b> <br/>
              Курс на платформе - Оставить пустым<br/>
              Предмет в оффлайн CRM системе - <b>Английский язык</b> <br/>
              Язык предмета в оффлайн CRM системе - <b>Русский</b> <br/>
            </li>
          </ol>
        </p>
      </Drawer>
    </>
  );
};

export default SubjectTransferNotes;
