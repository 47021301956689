import React from 'react';
import cn from 'classnames';

import styles from './SubjectForm.module.scss';
import { omit } from 'ramda';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { dictionaryToSelect, normFile } from '../../utils';
import CustomUpload from '../../components/CustomUpload';
import { useGetMetaSubjectsQuery } from '../../store/api/courses';
import { useGetLanguagesQuery } from '../../store/api/languages';

const { Title } = Typography;

const SubjectForm = ({
  initialValues,
  onSubmit,
  loading
}) => {
  const {
    data: metaSubjects,
    isLoading: isMetaSubjectsLoading,
    isFetching: isMetaSubjectsFetching,
  } = useGetMetaSubjectsQuery({ pageSize: 999 });
  const {
    data: languages,
    isLoading: isLanguagesLoading,
    isFetching: isLanguagesFetching,
  } = useGetLanguagesQuery({ pageSize: 999 });

  const onFinish = async (values) => {
    const preparedValues = omit(
      ['ru', 'en', 'uz'],
      {
        ...values,
        translates: [values.ru, values.en, values.uz],
      },
    );
    await onSubmit(preparedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={initialValues}
      disabled={loading}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col md={8}>
          <Form.Item
            label="Изображение обложки (показывается на странице предмета)"
            name="coverPicturePath"
            getValueFromEvent={normFile}
            required
          >
            <CustomUpload
              maxCount={1}
              multiple={false}
              group="subject_photo"
              existedFileUrl={initialValues.coverPictureUrl}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="sysName"
        label="Системное название"
        required
        rules={[
          { required: true, message: 'Системное название обязательно' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="order"
        label="Порядковый номер"
        required
        rules={[
          { required: true, message: 'Порядковый номер обязателен' }
        ]}
      >
        <InputNumber precision={0} />
      </Form.Item>
      <Form.Item
        name="isActive"
        valuePropName="checked"
      >
        <Checkbox>Активен?</Checkbox>
      </Form.Item>
      <Form.Item label="Отнести к предметной группе" name="metaSubject">
        <Select
          showSearch
          placeholder="Выберите предметную группу"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isMetaSubjectsLoading || isMetaSubjectsFetching}
          options={dictionaryToSelect(metaSubjects?.results)}
        />
      </Form.Item>
      <Form.Item label="Язык, на котором ведется этот курс" name="language">
        <Select
          showSearch
          placeholder="Выберите язык"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isLanguagesLoading || isLanguagesFetching}
          options={
            languages?.results
              ? languages.results.map(({ id, name }) => ({
                value: id,
                label: name,
              }))
              : []
          }
        />
      </Form.Item>
      <Row gutter={16}>
        <Col md={24}>
          <Title level={4}>Переводимые поля</Title>
        </Col>
        <Col md={8}>
          {/*   ================     RUSSIAN FIELDS     ================= */}
          <Title level={5}>Русский</Title>
          <Form.Item hidden name={['ru', 'language']} />
          <Form.Item
            name={['ru', 'name']}
            label="Название предмета"
            required
            rules={[
              { required: true, message: 'Название обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['ru', 'title']}
            label="Заголовок предмета"
            required
            rules={[
              { required: true, message: 'Заголовок обязателен' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['ru', 'description']}
            label="Описание предмета"
            required
            rules={[
              { required: true, message: 'Описание обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['ru', 'opportunity']}
            label="Возможности предмета"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={8}>
          {/*   ================     UZBEK FIELDS     ================= */}
          <Title level={5}>Узбекский</Title>
          <Form.Item hidden name={['uz', 'language']} />
          <Form.Item
            name={['uz', 'name']}
            label="Название предмета"
            required
            rules={[
              { required: true, message: 'Название обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['uz', 'title']}
            label="Заголовок предмета"
            required
            rules={[
              { required: true, message: 'Заголовок обязателен' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['uz', 'description']}
            label="Описание предмета"
            required
            rules={[
              { required: true, message: 'Описание обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['uz', 'opportunity']}
            label="Возможности предмета"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={8}>
          {/*   ================     ENGLISH FIELDS     ================= */}
          <Title level={5}>Английский</Title>
          <Form.Item hidden name={['en', 'language']} />
          <Form.Item
            name={['en', 'name']}
            label="Название предмета"
            required
            rules={[
              { required: true, message: 'Название обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['en', 'title']}
            label="Заголовок предмета"
            required
            rules={[
              { required: true, message: 'Заголовок обязателен' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['en', 'description']}
            label="Описание предмета"
            required
            rules={[
              { required: true, message: 'Описание обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['en', 'opportunity']}
            label="Возможности предмета"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Button htmlType="submit" type="primary" block loading={loading}>
        Сохранить
      </Button>
    </Form>
  );
};

export default SubjectForm;
