import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiRoutes, baseURL } from '../apiRoutes';

export const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: async (headers, { getState }) => {
    // Если нужно добавить заголовки ко всем запросам, то:
    // headers.set('Accept-Language', i18n.language);

    const accessToken = localStorage.getItem('accessToken');
    if (headers.get('Authorization')) return;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
  }
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error.status === 403 || result.error.status === 401)) {
    const refreshToken = await localStorage.getItem('refreshToken');
    if (refreshToken) {
      const queryParams = {
        url: apiRoutes.refreshToken(),
        method: 'POST',
        body: {
          refreshToken: refreshToken,
        },
      }
      try {
        const newCredentials = await baseQuery(queryParams, api, extraOptions);
        if (newCredentials.error) {
          await localStorage.clear();
          return result;
        }
        await localStorage.setItem('accessToken', newCredentials.data.accessToken);
        await localStorage.setItem('refreshToken', newCredentials.data.refreshToken);
        result = await baseQuery(args, api, extraOptions);
      } catch (e) {
        await localStorage.clear();
      }
    }
  }
  return result;
};
