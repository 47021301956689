import React from 'react';
import StudentList from '../../containers/StudentList';
import { Col, Form, Radio, Input, Row, Typography } from 'antd';
import FiltersDrawer from '../../components/FiltersDrawer';

const { Title } = Typography;

const Students = () => {

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Студенты</Title>
        </Col>
        <Col>
          <FiltersDrawer>
            <Form.Item name="phone" label="Телефон">
              <Input />
            </Form.Item>
            <Form.Item name="name" label="Фамилия или Имя или Отчество">
              <Input />
            </Form.Item>
            <Form.Item name="isOffline" label="Учится оффлайн">
              <Radio.Group>
                <Radio.Button value="true">Да</Radio.Button>
                <Radio.Button value="false">Нет</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </FiltersDrawer>
        </Col>
      </Row>
      <StudentList />
    </div>
  );
};

export default Students;
