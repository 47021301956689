import React from 'react';
import cn from 'classnames';

import styles from './ChapterList.module.scss';
import { useGetLessonChaptersQuery } from '../../store/api/courses';
import useQueryString from '../../hooks/useQueryString';
import { Skeleton, Table, Tag } from 'antd';
import { formatDate } from '../../utils';
import CommentApproval from '../CommentApproval';
import CommentRejection from '../CommentRejection';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';

const ChapterList = () => {
  const { page = 1, pageSize = 20, ...params } = useQueryString();
  const navigate = useNavigate();

  const {
    data: chapters,
    isLoading: isChapterLoading,
    isFetching: isChapterFetching,
  } = useGetLessonChaptersQuery({ page, pageSize, ...params });

  const columns = [
    {
      title: 'Системное название',
      dataIndex: 'sysName',
      key: 'sysName',
    },
    {
      title: 'Порядковый номер',
      dataIndex: 'order',
      key: 'order',
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  if (isChapterLoading) return <Skeleton />
  if (!chapters) return null;

  return (
    <Table
      dataSource={chapters.results}
      columns={columns}
      rowKey="id"
      loading={isChapterFetching}
      onRow={(record) => ({
        onClick: () => navigate(ROUTES.lessonChapterDetails(record.id))
      })}
      pagination={{
        total: chapters?.count,
        current: page,
        locale: { items_per_page: '/ на странице' },
        pageSize,
        showSizeChanger: true,
        showTitle: true,
        pageSizeOptions: [20, 50, 100],
      }}
      onChange={handlePageChange}
    />
  );
};

export default ChapterList;
