import React from 'react';
import cn from 'classnames';

import styles from './Tariffs.module.scss';
import TariffList from '../../containers/TariffList';
import { Button, Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';
import SysNameFilter from '../../components/SysNameFilter';

const { Title } = Typography;

const Tariffs = () => {
  const navigate = useNavigate();

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Тарифы</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(ROUTES.tariffDetails('create'))}>
            Создать тариф
          </Button>
        </Col>
      </Row>
      <div style={{ marginBottom: 12 }}>
        <SysNameFilter/>
      </div>
      <TariffList/>
    </div>
  );
};

export default Tariffs;
