import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getCrmSubjects: build.query({
      query: (params) => ({
        url: apiRoutes.crmSubjectList(),
        params,
      }),
      providesTags: ['CRM_SUBJECTS'],
    }),
    syncCrmSubjects: build.query({
      query: (params) => ({
        url: apiRoutes.syncCrmSubjectList(),
        params,
      }),
      providesTags: ['SYNC_CRM_SUBJECTS'],
    }),
    getSubjectTransferList: build.query({
      query: (params) => ({
        url: apiRoutes.subjectTransfer(),
        params,
      }),
      providesTags: ['SUBJECT_TRANSFER_LIST'],
    }),
    createSubjectTransfer: build.mutation({
      query: (body) => ({
        url: apiRoutes.subjectTransfer(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['SUBJECT_TRANSFER_LIST'] }),
    }),
    removeSubjectTransfer: build.mutation({
      query: (id) => ({
        url: apiRoutes.subjectTransfer(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['SUBJECT_TRANSFER_LIST'] }),
    }),
  }),
});

export const {
  useGetCrmSubjectsQuery,
  useLazySyncCrmSubjectsQuery,
  useGetSubjectTransferListQuery,
  useCreateSubjectTransferMutation,
  useRemoveSubjectTransferMutation,
} = mainApi;
