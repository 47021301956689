import React from 'react';
import cn from 'classnames';

import styles from './Chapters.module.scss';
import { Button, Col, Row, Typography } from 'antd';
import ChapterList from '../../containers/ChapterList';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';
import SysNameFilter from '../../components/SysNameFilter';

const { Title } = Typography;

const Chapters = () => {
  const navigate = useNavigate();

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Разделы для уроков</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(ROUTES.lessonChapterDetails('create'))}>
            Создать
          </Button>
        </Col>
      </Row>
      <div style={{ marginBottom: 12 }}>
        <SysNameFilter/>
      </div>
      <ChapterList/>
    </div>
  );
};

export default Chapters;
