import React from 'react';
import cn from 'classnames';

import styles from './TeacherDetails.module.scss';
import { useNavigate, useParams } from 'react-router';
import TeacherForm from '../../containers/TeacherForm';
import { message, Skeleton, Typography } from 'antd';
import { useCreateTeacherMutation, useEditTeacherMutation, useGetTeacherDetailsQuery } from '../../store/api/teachers';
import ROUTES from '../../router/ROUTES';
import dayjs from 'dayjs';
import { find, pluck, propEq } from 'ramda';

const { Title } = Typography;

const TeacherDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: teacher,
    isLoading: isTeacherLoading,
    isFetching: isTeacherFetching,
  } = useGetTeacherDetailsQuery(id, { skip: !id || id === 'create' });

  const [
    createTutor,
    {
      isLoading: isCreateTutorLoading,
      isFetching: isCreateTutorFetching,
    }
  ] = useCreateTeacherMutation();

  const [
    editTutor,
    {
      isLoading: isEditTutorLoading,
      isFetching: isEditTutorFetching,
    }
  ] = useEditTeacherMutation();

  const handleCreate = async (values) => {
    try {
      await createTutor(values).unwrap();
      message.success('Учитель создан');
      navigate(ROUTES.teachers());
    } catch (e) {
      console.error(e);
    }
  };

  const handleEdit = async (values) => {
    try {
      await editTutor({ teacherId: id, body: values }).unwrap();
      message.success('Учитель изменен');
    } catch (e) {
      console.error(e);
    }
  };

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      isActive: false,
    };
    if (!teacher) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...teacher,
      dateOfBirthday: dayjs(teacher.dateOfBirthday),
      metaSubjects: pluck('id')(teacher.metaSubjects),
      ru: find(propEq(1, 'language'))(teacher.translates),
      uz: find(propEq(2, 'language'))(teacher.translates),
      en: find(propEq(3, 'language'))(teacher.translates),
    }
  };

  return (
    <div className="main-content">
      <Title level={3}>
        {
          id === 'create'
            ? 'Создать учителя'
            : 'Детали учителя'
        }
      </Title>
      {
        id === 'create'
          ? (
            <TeacherForm
              onSubmit={handleCreate}
              initialValues={buildInitialValues()}
              loading={isCreateTutorLoading || isCreateTutorFetching}
            />
          ) : (
            (isTeacherLoading || !teacher)
              ? <Skeleton />
              : (
                <TeacherForm
                  onSubmit={handleEdit}
                  initialValues={buildInitialValues()}
                  loading={isEditTutorLoading || isEditTutorFetching}
                />
              )
          )
      }
    </div>
  );
};

export default TeacherDetails;
