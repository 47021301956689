import React from 'react';
import cn from 'classnames';

import styles from './AppLayout.module.scss';
import { Outlet } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import MenuList from '../MenuList';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';

const {
  Header,
  Content,
  Footer,
  Sider,
} = Layout;

const AppLayout = () => {
  const navigate = useNavigate();

  const handleExit = () => {
    localStorage.clear();
    navigate(ROUTES.root(), { replace: true });
  };

  return (
    <Layout hasSider>
      <Sider className={styles.sider}>
        <div className={styles.logo}>Административная панель fiveplus.uz</div>
        <MenuList />
      </Sider>
      <Layout className={styles.contentLayout}>
        <Header className={styles.contentHeader}>
          <Button type="link" danger onClick={handleExit}>
            Выход
          </Button>
        </Header>
        <Content className={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
