import React from 'react';
import cn from 'classnames';

import styles from './AboutServiceStepForm.module.scss';
import { find, omit, propEq } from 'ramda';
import { Button, Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import { normFile } from '../../utils';
import CustomUpload from '../../components/CustomUpload';

const { Title } = Typography;

const AboutServiceStepForm = ({
  initialValues,
  onSubmit,
  loading,
}) => {

  const onFinish = async (values) => {
    const preparedValues = omit(
      ['ru', 'en', 'uz'],
      {
        ...values,
        translates: [values.ru, values.en, values.uz],
        alias: 'default',
      },
    );
    await onSubmit(preparedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      disabled={loading}
      initialValues={initialValues}
    >
      <Form.Item
        name="sysName"
        label="Системное название (Используется только в админ. панеле)"
        rules={[
          {
            required: true,
            message: 'Системное имя обязательно'
          },
        ]}
        required
      >
        <Input placeholder="Выберите имя по которому сможете быстро определить FAQ" />
      </Form.Item>
      <Form.Item
        name="order"
        label="Порядковый номер для отображение в списке"
        rules={[
          {
            required: true,
            message: 'Системное имя обязательно'
          },
        ]}
        required
      >
        <InputNumber />
      </Form.Item>
      <Row gutter={16}>
        <Col md={24}>
          <Title level={4}>Переводимые поля</Title>
        </Col>
        <Col md={8}>
          <Title level={5}>Русский</Title>
          <Form.Item hidden name={['ru', 'language']} />
          <Form.Item
            label="Картинка (16:9)"
            name={['ru', 'picturePath']}
            getValueFromEvent={normFile}
            required
          >
            <CustomUpload
              maxCount={1}
              multiple={false}
              group="steps_photo"
              existedFileUrl={
                initialValues.translates
                  ? find(propEq(1, 'language'))(initialValues.translates)?.pictureUrl
                  : null
              }
            />
          </Form.Item>
          <Form.Item
            name={['ru', 'title']}
            label="Заголовок"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Заголовок первого шага" />
          </Form.Item>
          <Form.Item
            name={['ru', 'description']}
            label="Описание"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input.TextArea placeholder="Описание первого шага" autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Title level={5}>Узбекский</Title>
          <Form.Item hidden name={['uz', 'language']} />
          <Form.Item
            label="Картинка (16:9)"
            name={['uz', 'picturePath']}
            getValueFromEvent={normFile}
            required
          >
            <CustomUpload
              maxCount={1}
              multiple={false}
              group="steps_photo"
              existedFileUrl={
                initialValues.translates
                  ? find(propEq(2, 'language'))(initialValues.translates)?.pictureUrl
                  : null
              }
            />
          </Form.Item>
          <Form.Item
            name={['uz', 'title']}
            label="Заголовок"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Заголовок первого шага" />
          </Form.Item>
          <Form.Item
            name={['uz', 'description']}
            label="Описание"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input.TextArea placeholder="Описание первого шага" autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Title level={5}>Английский</Title>
          <Form.Item hidden name={['en', 'language']} />
          <Form.Item
            label="Картинка (16:9)"
            name={['en', 'picturePath']}
            getValueFromEvent={normFile}
            required
          >
            <CustomUpload
              maxCount={1}
              multiple={false}
              group="steps_photo"
              existedFileUrl={
                initialValues.translates
                  ? find(propEq(3, 'language'))(initialValues.translates)?.pictureUrl
                  : null
              }
            />
          </Form.Item>
          <Form.Item
            name={['en', 'title']}
            label="Заголовок"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Заголовок первого шага" />
          </Form.Item>
          <Form.Item
            name={['en', 'description']}
            label="Описание"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input.TextArea placeholder="Описание первого шага" autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
        </Col>

      </Row>
      <Button htmlType="submit" type="primary" block loading={loading}>
        Сохранить
      </Button>
    </Form>
  );
};

export default AboutServiceStepForm;
