import React from 'react';
import {
  useGetCrmSubjectsQuery,
  useGetSubjectTransferListQuery,
  useRemoveSubjectTransferMutation
} from '../../store/api/subjectTransfer';
import { Button, Table } from 'antd';
import { useGetLessonSubjectQuery, useGetMetaSubjectsQuery } from '../../store/api/courses';
import { find, propEq } from 'ramda';

const getDictNameByFieldName = (dicts, id, fieldName = 'id') => {
  if (!dicts) return '';
  const dict = find((el) => el[fieldName] == id, dicts);
  if (!dict) return '';
  if (dict.sysName) return dict.sysName;
  return dict.name;
};

const TransferSubjectList = () => {
  const {
    data: subjectTransfers,
    isFetching: isSubjectTransferFetching,
  } = useGetSubjectTransferListQuery({ page: -1 });

  const {
    data: metaSubjects,
    isFetching: isMetaSubjectFetching,
  } = useGetMetaSubjectsQuery({ page: -1 });
  const {
    data: subjects,
    isFetching: isSubjectFetching,
  } = useGetLessonSubjectQuery({ page: -1 });
  const {
    data: crmSubjects,
    isFetching: isCrmSubjectFetching,
  } = useGetCrmSubjectsQuery({ page: -1 });

  const [
    removeSubject,
    { isLoading: isRemoving, }
  ] = useRemoveSubjectTransferMutation();

  const columns = [
    {
      title: 'Предметная группа на платформе',
      dataIndex: 'metaSubject',
      key: 'metaSubject',
      render: (data) => getDictNameByFieldName(metaSubjects?.results, data),
    },
    {
      title: 'Курс на платформе',
      dataIndex: 'appSubject',
      key: 'appSubject',
      render: (data) => getDictNameByFieldName(subjects?.results, data),
    },
    {
      title: 'Предмет в оффлайн CRM системе',
      dataIndex: 'crmSubject',
      key: 'crmSubject',
      render: (data) => getDictNameByFieldName(crmSubjects?.results, data),
    },
    {
      title: 'Язык предмета в оффлайн CRM системе',
      dataIndex: 'crmLanguage',
      key: 'crmLanguage',
    },
    {
      title: 'Действия',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Button
          type="primary"
          danger
          onClick={async () => {
            try {
              await removeSubject(id).unwrap();
            } catch (e) {
              console.error(e);
            }
          }}
        >
          Удалить
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={subjectTransfers?.results}
        columns={columns}
        rowKey="id"
        loading={
          isSubjectTransferFetching
          || isMetaSubjectFetching
          || isSubjectFetching
          || isCrmSubjectFetching
          || isRemoving
        }
        pagination={{
          pageSize: 9999
        }}
      />
    </div>
  );
};

export default TransferSubjectList;
