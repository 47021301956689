import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptions: build.query({
      query: (params) => ({
        url: apiRoutes.subscriptions(),
        params,
      }),
      providesTags: ['SUBSCRIPTIONS'],
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
} = mainApi;
