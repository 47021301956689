import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  useCreateLessonSubjectMutation,
  useEditLessonSubjectMutation,
  useGetLessonSubjectDetailsQuery
} from '../../store/api/courses';
import { message, Skeleton } from 'antd';
import ROUTES from '../../router/ROUTES';
import { find, propEq } from 'ramda';
import SubjectForm from '../../containers/SubjectForm';

const SubjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: subject,
    isLoading: isSubjectLoading,
    isFetching: isSubjectFetching,
  } = useGetLessonSubjectDetailsQuery(id, { skip: !id || id === 'create' });

  const [
    createSubject,
    {
      isLoading: isCreateSubjectLoading,
      isFetching: isCreateSubjectFetching,
    },
  ] = useCreateLessonSubjectMutation();

  const [
    editSubject,
    {
      isLoading: isEditSubjectLoading,
      isFetching: isEditSubjectFetching,
    },
  ] = useEditLessonSubjectMutation();

  const onCreate = async (values) => {
    try {
      await createSubject(values).unwrap();
      message.success('Предмет создан');
      navigate(ROUTES.lessonSubjects());
    } catch (e) {
      console.error(e);
    }
  };

  const onEdit = async (values) => {
    try {
      await editSubject({ body: values, subjectId: id }).unwrap();
      message.success('Предмет изменен');
    } catch (e) {
      console.error(e);
    }
  };

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      isActive: false,
    };
    if (!subject) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...subject,
      ru: find(propEq(1, 'language'))(subject.translates) ?? rootInitialValues.ru,
      uz: find(propEq(2, 'language'))(subject.translates) ?? rootInitialValues.uz,
      en: find(propEq(3, 'language'))(subject.translates) ?? rootInitialValues.en,
    }
  };

  if (isSubjectLoading) return <Skeleton />;

  return (
    <div className="main-content">
      <SubjectForm
        initialValues={buildInitialValues()}
        onSubmit={id === 'create' ? onCreate : onEdit}
        loading={
          isCreateSubjectLoading
          || isCreateSubjectFetching
          || isEditSubjectLoading
          || isEditSubjectFetching
        }
      />
    </div>
  );
};

export default SubjectDetails;
