import React from 'react';
import cn from 'classnames';

import styles from './SubjectTransferDetails.module.scss';
import { useNavigate, useParams } from 'react-router';
import SubjectTransferForm from '../../containers/SubjectTransferForm';
import { useCreateSubjectTransferMutation } from '../../store/api/subjectTransfer';
import { message, Typography } from 'antd';
import ROUTES from '../../router/ROUTES';
import SubjectTransferSync from '../../containers/SubjectTransferSync';
import SubjectTransferNotes from '../../containers/SubjectTransferNotes';

const { Title } = Typography;

const SubjectTransferDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [
    createSubjectTransfer,
    {
      isLoading: isCreating,
    },
  ] = useCreateSubjectTransferMutation();

  const handleCreate = async (values) => {
    try {
      await createSubjectTransfer(values).unwrap();
      message.success('Связь создана');
      navigate(ROUTES.subjectTransfer());
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="main-content">
      <Title level={3}>
        Создать связь между предметами
        <SubjectTransferNotes />
      </Title>
      <SubjectTransferSync />
      <SubjectTransferForm
        onSubmit={handleCreate}
        loading={isCreating}
        initialValues={{}}
      />
    </div>
  );
};

export default SubjectTransferDetails;
