import { joinExisting } from './utils';

export const baseURL = process.env.REACT_APP_HOST;

export const apiRoutes = {
  login: (id) => ['account/staff/login'].join('/'),
  refreshToken: () => ['account/public/tokens/refresh'].join('/'),

  saveFile: (id) => ['static-content/staff/files'].join('/'),

  languages: () => ['core/languages/'].join('/'),

  // Comments
  comments: (id) => joinExisting(['admin/comments']) + '/',
  approveComment: (id) => joinExisting(['admin/comments', id, 'approve']) + '/',
  rejectComment: (id) => joinExisting(['admin/comments', id, 'reject']) + '/',

  // Teachers
  teachers: (id) => joinExisting(['admin/teachers', id]) + '/',

  // Tariff
  tariffs: (id) => joinExisting(['admin/tariffs', id]) + '/',

  // Subscriptions
  subscriptions: (id) => joinExisting(['admin/subscriptions', id]) + '/',

  // Students
  students: (id) => joinExisting(['admin/students', id]) + '/',

  // Lesson Chapters
  lessonChapters: (id) => joinExisting(['admin/chapters', id]) + '/',

  // Lesson Subjects
  lessonSubjects: (id) => joinExisting(['admin/subjects', id]) + '/',
  metaSubjects: (id) => joinExisting(['admin/meta-subjects', id]) + '/',

  // Lessons
  lessons: (id) => joinExisting(['admin/lessons', id]) + '/',
  lessonDocuments: (id) => joinExisting(['admin/lesson-documents', id]) + '/',

  // Video hosting
  videoList: () => joinExisting(['kinescope/video-list']),

  // FAQ
  faq: (id) => joinExisting(['admin/faqs', id]) + '/',

  // About Service Steps
  aboutServiceSteps: (id) => joinExisting(['admin/steps', id]) + '/',

  // About Service Youtube link
  staticContents: (id) => joinExisting(['admin/contents', id]) + '/',

  // Перекодировочные предметы
  crmSubjectList: () => joinExisting(['admin/crm-subjects/']),
  syncCrmSubjectList: () => joinExisting(['admin/crm-subjects/synch-subjects/']),
  subjectTransfer: (id) => joinExisting(['admin/subjects-transfer', id]) + '/',

  // Dashboard
  usersDashboard: () => joinExisting(['admin/reports/students/dashboard/']),
  subscriptionDashboard: () => joinExisting(['admin/reports/subscriptions/dashboard/']),
  subscriptionByTariffDashboard: () => joinExisting(['admin/reports/subscriptions/by-tariff/']),
  subscriptionBySubjectDashboard: () => joinExisting(['admin/reports/subscriptions/by-subject/']),
};
