import React from 'react';
import cn from 'classnames';

import styles from './CommentList.module.scss';
import { useGetCommentsQuery } from '../../store/api/comments';
import useQueryString from '../../hooks/useQueryString';
import { Skeleton, Table, Tag } from 'antd';
import { formatDate, joinExisting } from '../../utils';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import CommentRejection from '../CommentRejection';
import CommentApproval from '../CommentApproval';

const CommentList = () => {
  const { page = 1, pageSize = 20, ...params } = useQueryString();
  const navigate = useNavigate();

  const {
    data: comments,
    isLoading,
    isFetching,
  } = useGetCommentsQuery({ page, pageSize, ...params });

  const columns = [
    {
      title: 'Дата комментария',
      dataIndex: 'createAt',
      key: 'createAt',
      render: (date) => formatDate(date, true),
    },
    {
      title: 'Текст комментария',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Автор комментария',
      dataIndex: 'student',
      key: 'student',
      render: (student) => (
        joinExisting([student.lastName, student.firstName, student.secondName], ' ')
      ),
    },
    {
      title: 'К уроку',
      dataIndex: 'lesson',
      key: 'lesson',
      render: (lesson) => lesson.sysName,
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
      render: (state) => (
        state === 'waiting'
          ? <Tag color="blue">Новый</Tag>
          : state === 'approve'
            ? <Tag color="green">Одобрен</Tag>
            : <Tag color="red">Отклонен</Tag>
      ),
    },
    {
      title: 'Действие',
      dataIndex: 'id',
      key: 'id',
      render: (commentId, raw) => (
        <>
          {(raw.state !== 'approve') && <CommentApproval id={commentId} />}
          {(raw.state !== 'rejected') && <CommentRejection id={commentId} />}
        </>
      ),
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  if (isLoading) return <Skeleton />
  if (!comments) return null;

  return (
    <>
      <Table
        dataSource={comments.results}
        columns={columns}
        rowKey="id"
        loading={isFetching}
        pagination={{
          total: comments?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onChange={handlePageChange}
      />
    </>
  );
};

export default CommentList;
