import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTariffs: build.query({
      query: (params) => ({
        url: apiRoutes.tariffs(),
        params,
      }),
      providesTags: ['TARIFFS'],
    }),
    getTariffDetails: build.query({
      query: (tariffId) => ({
        url: apiRoutes.tariffs(tariffId),
      }),
      providesTags: ['TARIFF_DETAILS'],
    }),
    createTariff: build.mutation({
      query: (body) => ({
        url: apiRoutes.tariffs(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFFS'] }),
    }),
    editTariff: build.mutation({
      query: ({ body, tariffId }) => ({
        url: apiRoutes.tariffs(tariffId),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFFS', 'TARIFF_DETAILS'] }),
    }),
  }),
});

export const {
  useGetTariffsQuery,
  useGetTariffDetailsQuery,
  useCreateTariffMutation,
  useEditTariffMutation,
} = mainApi;
