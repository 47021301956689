import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getComments: build.query({
      query: (params) => ({
        url: apiRoutes.comments(),
        params,
      }),
      providesTags: ['COMMENTS'],
    }),
    rejectComment: build.mutation({
      query: (commentId) => ({
        url: apiRoutes.rejectComment(commentId),
        method: 'PATCH',
      }),
      invalidatesTags: invalidateOn({ success: ['COMMENTS'] }),
    }),
    approveComment: build.mutation({
      query: (commentId) => ({
        url: apiRoutes.approveComment(commentId),
        method: 'PATCH',
      }),
      invalidatesTags: invalidateOn({ success: ['COMMENTS'] }),
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useRejectCommentMutation,
  useApproveCommentMutation,
} = mainApi;
