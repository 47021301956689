import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation({
      query: (body) => ({
        url: apiRoutes.saveFile(),
        method: 'POST',
        body,
      }),
    })
  })
});

export const {
  useUploadMutation,
} = mainApi;
