import { filter, identity, join, pipe, pluck, propEq } from 'ramda';
import dayjs from 'dayjs';

export const formatDate = (date, withTime) => {
  if (!date) return null;
  const dateObj = dayjs(date);
  if (!dateObj.isValid()) return null;
  return withTime
    ? dateObj.format('DD.MM.YYYY (HH:mm)')
    : dateObj.format('DD.MM.YYYY');
};

export const formatTime = (date) => {
  if (!date) return null;
  const dateObj = dayjs(date);
  if (!dateObj.isValid()) return null;
  return dateObj.format('HH:mm');
};


export const toSom = (string) => {
  const num = Number(string);
  if (!string) return string;
  if (Number.isNaN(num)) return string;
  return String(Math.round(num / 100));
};

export const toTiin = (string) => {
  const num = Number(string);
  if (!string) return string;
  if (Number.isNaN(num)) return string;
  return String(Math.round(num * 100));
};

const formatter = new Intl.NumberFormat('ru-RU');

export const formatNumber = (number, appendix = null, isTiin = false) => {
  if (number === 0) return String(number);
  if (!number || Number.isNaN(number)) {
    return null;
  }
  if (isTiin) {
    const formattedNum = formatter.format(toSom(number));
    return appendix ? `${formattedNum} ${appendix}` : formattedNum;
  }
  const formattedNum = formatter.format(number);
  return appendix ? `${formattedNum} ${appendix}` : formattedNum;
};

export const joinExisting = (arr, separator = '/') => pipe(
  filter(identity),
  join(separator),
)(arr);


export const normFile = (e) => {
  const { file: { response, status } } = e;
  console.log(e);
  if (!response || !response.path) return;

  if (status === 'removed') return;

  return response.path;
};

export const normLessonDocument = (e) => {
  const { fileList,  } = e;

  const fileResponses = pipe(
    filter((file) => file.status !== 'removed'),
    filter((file) => file.response),
    pluck('response'),
  )(fileList);

  return fileResponses;
};

export const dictionaryToSelect = (dict) => {
  if (!dict) return [];
  return dict.map((d) => ({
    value: d.id,
    label: d.sysName,
  }));
};

export const videoHostingDictionaryToSelect = (dict) => {
  if (!dict) return [];
  return dict.map((d) => ({
    value: d.id,
    label: d.title,
  }));
};

export const crmSubjectDictionaryToSelect = (dict) => {
  if (!dict) return [];
  return dict.map((d) => ({
    value: d.id,
    label: d.name,
  }));
};
