import React from 'react';
import cn from 'classnames';

import styles from './FAQForm.module.scss';
import { Button, Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import { omit } from 'ramda';

const { Title } = Typography;

const FAQForm = ({
  onSubmit,
  initialValues,
  loading,
}) => {

  const onFinish = async (values) => {
    const preparedValues = omit(
      ['ru', 'en', 'uz'],
      {
        ...values,
        translates: [values.ru, values.en, values.uz],
        alias: 'default',
      },
    );
    await onSubmit(preparedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      disabled={loading}
      initialValues={initialValues}
    >
      <Form.Item
        name="sysName"
        label="Системное название (Используется только в админ. панеле)"
        rules={[
          {
            required: true,
            message: 'Системное имя обязательно'
          },
        ]}
        required
      >
        <Input placeholder="Выберите имя по которому сможете быстро определить FAQ" />
      </Form.Item>
      <Form.Item
        name="order"
        label="Порядковый номер для отображение в списке"
        rules={[
          {
            required: true,
            message: 'Системное имя обязательно'
          },
        ]}
        required
      >
        <InputNumber />
      </Form.Item>
      <Row gutter={16}>
        <Col md={24}>
          <Title level={4}>Переводимые поля</Title>
        </Col>
        <Col md={8}>
          <Title level={5}>Русский</Title>
          <Form.Item hidden name={['ru', 'language']} />
          <Form.Item
            name={['ru', 'question']}
            label="Вопрос"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Сколько стоит подписка?" />
          </Form.Item>
          <Form.Item
            name={['ru', 'answer']}
            label="Ответ"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input.TextArea placeholder="Ответ на вопрос" autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Title level={5}>Узбекский</Title>
          <Form.Item hidden name={['uz', 'language']} />
          <Form.Item
            name={['uz', 'question']}
            label="Вопрос"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Сколько стоит подписка?" />
          </Form.Item>
          <Form.Item
            name={['uz', 'answer']}
            label="Ответ"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input.TextArea placeholder="Ответ на вопрос" autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
        </Col>

        <Col md={8}>
          <Title level={5}>Английский</Title>
          <Form.Item hidden name={['en', 'language']} />
          <Form.Item
            name={['en', 'question']}
            label="Вопрос"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Сколько стоит подписка?" />
          </Form.Item>
          <Form.Item
            name={['en', 'answer']}
            label="Ответ"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input.TextArea placeholder="Ответ на вопрос" autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
        </Col>
      </Row>
      <Button htmlType="submit" type="primary" block loading={loading}>
        Сохранить
      </Button>
    </Form>
  );
};

export default FAQForm;
