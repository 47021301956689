// import cn from 'classnames';

import { Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { useLazyGetStudentsQuery } from '../store/api/students';
import { joinExisting } from '../utils';

const DebounceSearchSelect = ({
  value,
  onChange,
  useLazyQuery,
  searchField,
  mapToOptions,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [options, setOptions] = useState([]);

  const [
    search,
    { isFetching },
  ] = useLazyQuery();

  useEffect(() => {
    const searchHN = async () => {
      try {
        if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
          const response = await search({ page: -1, [searchField]: debouncedSearchTerm }).unwrap();

          const options = mapToOptions(response.results);
          setOptions(options);
        } else {
          setOptions([]);
        }
      } catch (e) {

      }
    };

    searchHN();
  }, [debouncedSearchTerm]);

  return (
    <Select
      filterOption={false}
      showSearch
      onSearch={(v) => {
        setSearchValue(v);
      }}
      onChange={onChange}
      value={value}
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      options={options}
      placeholder="Для поиска нужно ввести минимум 3 символа"
    />
  );
};

export default DebounceSearchSelect;
