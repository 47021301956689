// import cn from 'classnames';

import { DatePicker, Form, Radio, Select, Space } from 'antd';
import FiltersDrawer from '../components/FiltersDrawer';
import React from 'react';
import DebounceSearchSelect from '../components/DebounceSearchSelect';
import { useLazyGetStudentsQuery } from '../store/api/students';
import { formatDate, joinExisting } from '../utils';
import { useLazyGetLessonSubjectQuery } from '../store/api/courses';
import { useGetTariffsQuery } from '../store/api/tariffs';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const SubscriptionFilter = () => {
  const {
    data: tariffs,
    isFetching,
  } = useGetTariffsQuery({ page: -1 });

  return (
    <FiltersDrawer>
      <Form.Item name="state" label="Состояние">
        <Radio.Group>
          <Radio.Button value="paid">Оплачено</Radio.Button>
          <Radio.Button value="init">Выставлен счет (неоплачено)</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="student" label="Студент">
        <DebounceSearchSelect
          useLazyQuery={useLazyGetStudentsQuery}
          searchField="name"
          mapToOptions={(results) => {
            if (results) {
              return results.map((student) => ({
                value: student.id,
                label: joinExisting([student.lastName, student.firstName, student.secondName], ' '),
              }));
            }
            return [];
          }}
        />
      </Form.Item>
      <Form.Item name="subject" label="Предмет">
        <DebounceSearchSelect
          useLazyQuery={useLazyGetLessonSubjectQuery}
          searchField="sysName"
          mapToOptions={(results) => {
            if (results) {
              return results.map((subject) => ({
                value: subject.id,
                label: subject.sysName,
              }));
            }
            return [];
          }}
        />
      </Form.Item>
      <Form.Item name="tariff" label="Тариф">
        <Select
          options={
            tariffs?.results
              ? tariffs.results.map((result) => ({
                value: result.id,
                label: result.sysName,
              }))
              : []
          }
        />
      </Form.Item>
      <Space>
        <Form.Item
          name="createAtFrom"
          label="Дата покупки (с)"
          normalize={(value) => value.utc().startOf('day')}
        >
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item
          name="createAtTo"
          label="Дата покупки (по)"
          normalize={(value) => value.utc().startOf('day')}
        >
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
      </Space>
    </FiltersDrawer>
  );
};

export default SubscriptionFilter;
