import React from 'react';
import cn from 'classnames';

import styles from './TariffDetails.module.scss';
import { useNavigate, useParams } from 'react-router';
import { message, Skeleton, Typography } from 'antd';
import TariffForm from '../../containers/TariffForm';
import dayjs from 'dayjs';
import { find, propEq } from 'ramda';
import {
  useCreateTariffMutation,
  useEditTariffMutation,
  useGetTariffDetailsQuery,
  useGetTariffsQuery
} from '../../store/api/tariffs';
import ROUTES from '../../router/ROUTES';

const { Title } = Typography;

const TariffDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: tariff,
    isLoading: isTariffLoading,
    isFetching: isTariffFetching,
  } = useGetTariffDetailsQuery(id, { skip: !id || id === 'create' });

  const [
    createTariff,
    {
      isLoading: isCreateTariffLoading,
      isFetching: isCreateTariffFetching,
    }
  ] = useCreateTariffMutation();

  const [
    editTariff,
    {
      isLoading: isEditTariffLoading,
      isFetching: isEditTariffFetching,
    }
  ] = useEditTariffMutation();

  const handleCreate = async (values) => {
    try {
      await createTariff(values).unwrap();
      message.success('Тариф отредактирован');
      navigate(ROUTES.tariffs());
    } catch (e) {
      console.error(values);
    }
  };

  const handleEdit = async (values) => {
    try {
      await editTariff({ tariffId: id, body: values }).unwrap();
      message.success('Тариф отредактирован');
    } catch (e) {
      console.error(values);
    }
  };

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      isActive: false,
    };
    if (!tariff) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...tariff,
      ru: find(propEq(1, 'language'))(tariff.translates),
      uz: find(propEq(2, 'language'))(tariff.translates),
      en: find(propEq(3, 'language'))(tariff.translates),
    };
  };

  if (isTariffLoading) return <Skeleton />;

  return (
    <div className="main-content">
      <Title level={3}>{id === 'create' ? 'Создать тариф' : 'Тариф'}</Title>
      {
        id === 'create'
          ? (
            <TariffForm
              initialValues={buildInitialValues()}
              onSubmit={handleCreate}
              loading={isCreateTariffLoading || isCreateTariffFetching}
            />
          ) : (
            (tariff || !isTariffLoading)
              ? (
                <TariffForm
                  initialValues={buildInitialValues()}
                  onSubmit={handleEdit}
                  loading={isEditTariffLoading || isEditTariffFetching}
                />
              ) : null
          )
      }
    </div>
  );
};

export default TariffDetails;
