import React from 'react';
import cn from 'classnames';

import styles from './SubjectTransferSync.module.scss';
import { Button, message } from 'antd';
import { useLazySyncCrmSubjectsQuery } from '../../store/api/subjectTransfer';

const SubjectTransferSync = () => {
  const [
    syncCrmSubjects,
    { isFetching: isSyncing }
  ] = useLazySyncCrmSubjectsQuery();

  const sync = async () => {
    try {
      await syncCrmSubjects().unwrap();
      message.success('Предметы синхронизированны');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Button
      type="primary"
      style={{ marginBottom: 10 }}
      onClick={sync}
      loading={isSyncing}
    >
      Синхронизировать предметы из Оффлайн CRM Системы
    </Button>
  );
};

export default SubjectTransferSync;
