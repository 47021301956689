import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getStaticContent: build.query({
      query: (id) => ({
        url: apiRoutes.staticContents(id),
      }),
      providesTags: ['STATIC_CONTENTS'],
    }),
    editStaticContent: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.staticContents(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['STATIC_CONTENTS'] }),
    }),
  }),
});

export const {
  useGetStaticContentQuery,
  useEditStaticContentMutation,
} = mainApi;
