import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import routes from './ROUTES';
// import cn from 'classnames';

const ProtectRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      navigate(routes.root(), { replace: true });
    }
  }, [navigate]);

  return children;
};

export default ProtectRoute;
