// import cn from 'classnames';

import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useGetSubscriptionByTariffDashboardQuery, useGetSubscriptionDashboardQuery } from '../store/api/dashboard';
import { Card, DatePicker, List, Space, Table, Typography } from 'antd';
import { formatDate, formatNumber } from '../utils';

const SubscriptionByTariff = () => {
  const [datesEditing, setDatesEditing] = useState(false);

  const [dates, setDates] = useState([dayjs().startOf('month'), dayjs()]);

  const {
    data: stats,
  } = useGetSubscriptionByTariffDashboardQuery({
    start: dates[0].format('YYYY-MM-DD'),
    end: dates[1].format('YYYY-MM-DD'),
  });

  return (
    <Card size="small" style={{ height: '100%' }}>
      <Typography.Title level={4}>По тарифам</Typography.Title>
      <div>
        <Typography.Text>
          <Space>
            <div>За период</div>
            <div>
              {
                datesEditing
                  ? (
                    <DatePicker.RangePicker
                      value={dates}
                      onChange={(newDates) => {
                        setDates(newDates);
                        setDatesEditing(false)
                      }}
                      format="DD.MM.YYYY"
                    />
                  ) : (
                    <Typography.Text onClick={() => setDatesEditing(true)} type="warning" style={{ cursor: 'pointer' }}>
                      с {formatDate(dates[0])} по {formatDate(dates[1])}
                    </Typography.Text>
                  )
              }
            </div>
          </Space>
        </Typography.Text>
        <Table
          style={{ marginTop: 12 }}
          columns={[
            {
              dataIndex: 'sysName',
              key: 'sysName',
              title: 'Тариф'
            },
            {
              dataIndex: 'onlineSubscriptionInPeriodCount',
              key: 'onlineSubscriptionInPeriodCount',
              title: 'Кол-во',
              render: (value) => (
                <Typography.Text type={value > 0 ? 'primary' : 'secondary'} strong={value > 0}>{value}</Typography.Text>
              )
            },
            {
              dataIndex: 'onlineSubscriptionInPeriodPriceSum',
              key: 'onlineSubscriptionInPeriodPriceSum',
              title: 'Сумма (сум)',
              render: (value) => (
                <Typography.Text type={value > 0 ? 'primary' : 'secondary'} strong={value > 0}>
                  {formatNumber(value)}
                </Typography.Text>
              )
            },
          ]}
          dataSource={stats?.rows}
        />

      </div>
    </Card>
  );
};

export default SubscriptionByTariff;
