import React from 'react';
import cn from 'classnames';

import styles from './ChapterDetails.module.scss';
import { useNavigate, useParams } from 'react-router';
import ChapterForm from '../../containers/ChapterForm';
import dayjs from 'dayjs';
import { find, propEq } from 'ramda';
import {
  useCreateLessonChapterMutation, useEditLessonChapterMutation,
  useGetLessonChapterDetailsQuery,
  useGetLessonChaptersQuery
} from '../../store/api/courses';
import { message, Skeleton } from 'antd';
import ROUTES from '../../router/ROUTES';

const ChapterDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: chapter,
    isLoading: isChapterLoading,
    isFetching: isChapterFetching,
  } = useGetLessonChapterDetailsQuery(id, { skip: !id || id === 'create' });

  const [
    createChapter,
    {
      isLoading: isCreateChapterLoading,
      isFetching: isCreateChapterFetching,
    },
  ] = useCreateLessonChapterMutation();

  const [
    editChapter,
    {
      isLoading: isEditChapterLoading,
      isFetching: isEditChapterFetching,
    },
  ] = useEditLessonChapterMutation();

  const onCreate = async (values) => {
    try {
      await createChapter(values).unwrap();
      message.success('Раздел создан');
      navigate(ROUTES.lessonChapters());
    } catch (e) {
      console.error(e);
    }
  };

  const onEdit = async (values) => {
    try {
      await editChapter({ body: values, chapterId: id }).unwrap();
      message.success('Раздел изменен');
    } catch (e) {
      console.error(e);
    }
  };

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      isActive: false,
    };
    if (!chapter) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...chapter,
      ru: find(propEq(1, 'language'))(chapter.translates),
      uz: find(propEq(2, 'language'))(chapter.translates),
      en: find(propEq(3, 'language'))(chapter.translates),
    }
  };

  if (isChapterLoading) return <Skeleton />;

  return (
    <div className="main-content">
      <ChapterForm
        initialValues={buildInitialValues()}
        onSubmit={id === 'create' ? onCreate : onEdit}
        loading={
          isCreateChapterLoading
          || isCreateChapterFetching
          || isEditChapterLoading
          || isEditChapterFetching
        }
      />
    </div>
  );
};

export default ChapterDetails;
