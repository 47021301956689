import { isRejectedWithValue } from '@reduxjs/toolkit';
import { message, Modal } from 'antd';
import router from '../router';
import routes from '../router/ROUTES';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (!isRejectedWithValue(action)) return next(action);

  const response = action.payload;

  if (response.status === 500) {
    console.error('Произошла ошибка 500');
  }

  if (response.status === 401) {
    message.error('Срок вашей сессии истек');
    router.navigate(routes.root());
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return next(action);
  }

  if (response.data) {
    if (response.data.type === 'form') {
      const { details } = response.data;
      message.error(`${response.status} - ${response.data.message}`);
      Modal.error({
        title: 'Исправьте ошибки',
        content: (
          <div>
            {details.map(({ name, errors }, i) => (
              <div key={i}>{name}: {errors.join(', ')}</div>
            ))}
          </div>
        ),
      });
      return next(action);
    }
    const { message: errorMessage } = response.data;
    message.error(errorMessage);
    return next(action);
  }
  message.error(`${action.payload.status} - ${action.payload.error}`);

  return next(action);
}
