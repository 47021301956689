import React from 'react';
import cn from 'classnames';

import styles from './MenuList.module.scss';
import { Menu } from 'antd';
import {
  AppstoreAddOutlined,
  AppstoreOutlined, BookOutlined,
  ContactsOutlined,
  ContainerOutlined, DesktopOutlined, DollarOutlined, PicCenterOutlined, QuestionCircleOutlined,
  SmileOutlined,
  WechatOutlined, YoutubeFilled
} from '@ant-design/icons';
import ROUTES from '../../router/ROUTES';
import { useLocation, useNavigate } from 'react-router';

const MenuList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      key: ROUTES.welcome(),
      icon: <AppstoreOutlined />,
      label: 'Отчет',
    },
    {
      key: ROUTES.students(),
      icon: <SmileOutlined />,
      label: 'Студенты',
    },
    {
      key: ROUTES.subscriptions(),
      icon: <DollarOutlined />,
      label: 'Подписки студентов',
    },
    {
      key: ROUTES.comments(),
      icon: <WechatOutlined />,
      label: 'Комментарии',
    },
    {
      key: ROUTES.teachers(),
      icon: <ContactsOutlined />,
      label: 'Учителя',
    },
    {
      key: ROUTES.faq(),
      icon: <QuestionCircleOutlined />,
      label: 'FAQ',
    },
    {
      key: ROUTES.aboutServiceSteps(),
      icon: <AppstoreAddOutlined />,
      label: 'Шаги для раздела "О сервисе"',
    },
    {
      key: ROUTES.staticData(),
      icon: <YoutubeFilled />,
      label: 'Ссылка на YouTube "О сервисе"',
    },
    {
      key: ROUTES.tariffs(),
      icon: <ContainerOutlined />,
      label: 'Тарифы',
    },
    {
      key: ROUTES.metaSubjects(),
      icon: <BookOutlined />,
      label: 'Предметная группа',
    },
    {
      key: ROUTES.lessonSubjects(),
      icon: <BookOutlined />,
      label: 'Курсы',
    },
    {
      key: ROUTES.lessonChapters(),
      icon: <PicCenterOutlined />,
      label: 'Разделы предмета',
    },
    {
      key: ROUTES.lessons(),
      icon: <DesktopOutlined />,
      label: 'Уроки',
    },
    {
      key: ROUTES.subjectTransfer(),
      icon: <DesktopOutlined />,
      label: 'Предметная связь',
    },
  ];

  const handleClickMenu = (item) => {
    navigate(item.key);
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[location.pathname]}
      items={items}
      onClick={handleClickMenu}
    />
  );
};

export default MenuList;
