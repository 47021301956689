import React from 'react';
import { Col, Row, Skeleton, Table, Tag, Typography } from 'antd';
import { useGetSubscriptionsQuery } from '../../store/api/subscriptions';
import useQueryString from '../../hooks/useQueryString';
import { formatDate, joinExisting } from '../../utils';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import SubscriptionFilter from '../../containers/SubscriptionFilter';

const { Title } = Typography;

const Subscriptions = () => {
  const { page = 1, pageSize = 20, ...params } = useQueryString();
  const navigate = useNavigate();

  const {
    data: subscriptions,
    isLoading,
    isFetching,
  } = useGetSubscriptionsQuery({
    page,
    pageSize,
    ...params,
    isActive: true,
  });


  const columns = [
    {
      title: 'Пользователь',
      dataIndex: 'student',
      key: 'student.lastName',
      sorter: true,
      render: (student) => (
        joinExisting([student.lastName, student.firstName, student.secondName], ' ')
      ),
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'student',
      key: 'student',
      sorter: true,
      render: (student) => (
        formatDate(student.registrationAt, true)
      ),
    },
    {
      title: 'Номер телефона',
      dataIndex: 'student',
      key: 'studentPhone',
      render: (student) => student.phone
    },
    {
      title: 'Тариф',
      dataIndex: 'tariff',
      key: 'tariff',
      render: (tariff) => tariff?.sysName || '-',
    },
    {
      title: 'Предметы',
      dataIndex: 'subjects',
      key: 'subjects',
      render: (subjects) => (
        <>
          {subjects.map((subject) => (
            <Tag key={subject.id}>{subject.sysName}</Tag>
          ))}
        </>
      )
    },
    {
      title: 'Действует с',
      dataIndex: 'startAt',
      key: 'startAt',
      sorter: true,
      render: (date) => formatDate(date, true),
    },
    {
      title: 'Действует по',
      dataIndex: 'finishAt',
      key: 'finishAt',
      sorter: true,
      render: (date) => formatDate(date, true),
    },
  ];

  const handlePageChange = (pagination, filter, sorter) => {
    const {
      columnKey, // key
      column, // object
      field,
      order, // descend, ascend
    } = sorter;

    const sortSymbol = order === 'descend' ? '-' : '';

    const sort = order ? `${sortSymbol}${columnKey}` : null;

    navigate({
      pathname: '',
      search: queryString.stringify({
        page: pagination.current,
        pageSize: pagination.pageSize,
        ...params,
        sort,
      }),
    })
  };

  if (isLoading) return <Skeleton />;
  if (!subscriptions) return null;

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Подписки студентов</Title>
        </Col>
        <Col>
          <SubscriptionFilter />
        </Col>
      </Row>

      <Table
        dataSource={subscriptions.results}
        columns={columns}
        rowKey="id"
        loading={isFetching}
        locale={{
          triggerDesc: 'Сортировать по убыванию',
          triggerAsc: 'Сортировать по возрастанию',
          cancelSort: 'Отменить сортировку'
        }}
        pagination={{
          total: subscriptions?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default Subscriptions;
