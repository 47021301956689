import React from 'react';
import cn from 'classnames';

import styles from './MetaSubjectDetails.module.scss';
import {
  useCreateMetaSubjectMutation,
  useEditMetaSubjectMutation,
  useGetMetaSubjectDetailsQuery
} from '../../store/api/courses';
import { useNavigate, useParams } from 'react-router';
import { message, Skeleton } from 'antd';
import MetaSubjectForm from '../../containers/MetaSubjectForm';
import { find, propEq } from 'ramda';
import ROUTES from '../../router/ROUTES';

const MetaSubjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: metaSubjectDetails,
    isLoading: isMetaSubjectDetailsLoading,
    isFetching: isMetaSubjectDetailsFetching,
  } = useGetMetaSubjectDetailsQuery(id, { skip: !id || id === 'create' });

  const [
    createMetaSubject,
    { isLoading: isCreateMetaSubjectLoading, isFetching: isCreateMetaSubjectFetching },
  ] = useCreateMetaSubjectMutation();

  const [
    editMetaSubject,
    { isLoading: isEditMetaSubjectLoading, isFetching: isEditMetaSubjectFetching },
  ] = useEditMetaSubjectMutation();

  const onCreate = async (values) => {
    try {
      await createMetaSubject(values).unwrap();
      message.success('Предметная группа создана');
      navigate(ROUTES.metaSubjects());
    } catch (e) {
      console.error(e);
    }
  };

  const onEdit = async (values) => {

    try {
      await editMetaSubject({ body: values, subjectId: id }).unwrap();
      message.success('Предметная группа изменена');
    } catch (e) {
      console.error(e);
    }
  };

  if (isMetaSubjectDetailsLoading) return <Skeleton />

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      isActive: false,
    };
    if (!metaSubjectDetails) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...metaSubjectDetails,
      ru: find(propEq(1, 'language'))(metaSubjectDetails.translates) ?? rootInitialValues.ru,
      uz: find(propEq(2, 'language'))(metaSubjectDetails.translates) ?? rootInitialValues.uz,
      en: find(propEq(3, 'language'))(metaSubjectDetails.translates) ?? rootInitialValues.en,
    }
  };

  return (
    <div className="main-content">
      <MetaSubjectForm
        initialValues={buildInitialValues()}
        onSubmit={id === 'create' ? onCreate : onEdit}
        loading={
          isMetaSubjectDetailsFetching
          || isCreateMetaSubjectLoading
          || isCreateMetaSubjectFetching
          || isEditMetaSubjectLoading
          || isEditMetaSubjectFetching
        }
      />
    </div>
  );
};

export default MetaSubjectDetails;
