import React from 'react';
import cn from 'classnames';

import styles from './LessonList.module.scss';
import { useNavigate } from 'react-router';
import useQueryString from '../../hooks/useQueryString';
import { useGetLessonsQuery, useGetLessonSubjectQuery } from '../../store/api/courses';
import { Button, Col, Form, Input, Row, Select, Skeleton, Table, Tag } from 'antd';
import queryString from 'query-string';
import ROUTES from '../../router/ROUTES';

const LessonList = () => {
  const navigate = useNavigate();
  const {
    page = 1,
    pageSize = 20,
    ...params
  } = useQueryString();

  const {
    data: subjectData,
    isFetching: isSubjectsFetching
  } = useGetLessonSubjectQuery({ page: -1 });

  const {
    data: lessons,
    isLoading: isLessonsLoading,
    isFetching: isLessonsFetching,
  } = useGetLessonsQuery({ page, pageSize, ...params });

  const columns = [
    {
      title: 'Изображение урока',
      dataIndex: 'pictureUrl',
      key: 'pictureUrl',
      render: (image) => <img src={image} alt="" style={{ width: 80 }} />
    },
    {
      title: 'Системное название',
      dataIndex: 'sysName',
      key: 'sysName',
    },
    {
      title: 'Порядковый номер',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Предмет',
      dataIndex: 'subject',
      key: 'subject',
      render: (subject) => subject.sysName,
    },
    {
      title: 'Раздел',
      dataIndex: 'chapter',
      key: 'chapter',
      render: (chapter) => chapter.sysName,
    },
    {
      title: 'Состояние',
      dataIndex: 'isFree',
      key: 'isFree',
      render: (isFree) => (
        isFree ? <Tag color="green">Бесплатный</Tag> : null
      )
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  const handleSearch = (values) => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page: 1,
        pageSize,
        ...values,
      }),
    });
  };

  const handleReset = () => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page: 1,
        pageSize,
      }),
    });
  };

  if (isLessonsLoading) return <Skeleton />
  if (!lessons) return null;

  return (
    <>
      <Form
        onFinish={handleSearch}
        layout="vertical"
      >
        <Row gutter={30} align="middle">
          <Col md={6}>
            <Form.Item name="q" label="Поиск">
              <Input placeholder="Введите системное название" />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item label="Предмет" name="subject">
              <Select
                loading={isSubjectsFetching}
                options={subjectData?.results.map((subj) => ({
                  value: subj.id,
                  label: subj.sysName,
                }))}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary" style={{ marginTop: 4 }}>
              Найти
            </Button>
            <Button
              htmlType="reset"
              style={{ marginTop: 4, marginLeft: 20 }}
              onClick={handleReset}
            >
              Сбросить
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={lessons.results}
        columns={columns}
        rowKey="id"
        loading={isLessonsFetching}
        onRow={(record) => ({
          onClick: () => navigate(ROUTES.lessonDetails(record.id))
        })}
        pagination={{
          total: lessons?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onChange={handlePageChange}
      />
    </>
  );
};

export default LessonList;
