import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './LessonForm.module.scss';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Typography,
  Upload
} from 'antd';
import { dictionaryToSelect, normFile, normLessonDocument, videoHostingDictionaryToSelect } from '../../utils';
import CustomUpload from '../../components/CustomUpload';
import {
  useCreateLessonDocumentMutation, useDeleteLessonDocumentMutation,
  useGetLessonChaptersQuery,
  useGetLessonSubjectQuery
} from '../../store/api/courses';
import { init, omit } from 'ramda';
import FileUploads from '../../components/FileUploads';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useUploadMutation } from '../../store/api/files';
import { useGetVideoListQuery } from '../../store/api/videoHosting';

const { Title } = Typography;

const LessonForm = ({
  initialValues,
  loading,
  onSubmit,
  mode,
  lessonId,
}) => {

  const {
    data: subjects,
    isLoading: isSubjectsLoading,
    isFetching: isSubjectsFetching,
  } = useGetLessonSubjectQuery({ page: -1 });

  const {
    data: chapters,
    isLoading: isChaptersLoading,
    isFetching: isChaptersFetching,
  } = useGetLessonChaptersQuery({ page: -1 });

  const {
    data: videos,
    isLoading: isVideosLoading,
    isFetching: isVideosFetching,
  } = useGetVideoListQuery({ per_page: 2000 });

  const [
    uploadFile,
    {
      isLoading: isUploadFileLoading,
      isFetching: isUploadFileFetching,
    }
  ] = useUploadMutation();

  const [
    createLessonDocument,
    {
      isLoading: isCreateLessonDocumentLoading,
      isFetching: isCreateLessonDocumentFetching,
    },
  ] = useCreateLessonDocumentMutation();

  const [
    deleteLessonDocument,
    {
      isLoading: isDeleteLessonDocumentLoading,
      isFetching: isDeleteLessonDocumentFetching,
    },
  ] = useDeleteLessonDocumentMutation();

  const onFinish = async (values) => {
    const preparedValues = omit(
      ['ru', 'en', 'uz', 'ruFiles', 'uzFiles', 'enFiles'],
      {
        ...values,
        lessonVideoId: values.lessonVideoId ?? null,
        translates: [values.ru, values.en, values.uz],
      },
    );
    await onSubmit(preparedValues);
  };

  const deleteDocument = (documentId) => async () => {
    try {
      await deleteLessonDocument(documentId).unwrap();
      message.success('Документ удален');
    } catch (e) {
      console.error(e);
    }
  };

  const customUploadRequest = useCallback((lang) => async ({ onSuccess, onError, onProgress, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group', 'lesson_documents');

    try {
      const data = await uploadFile(formData).unwrap();
      await createLessonDocument({
        ...data,
        filePath: data.path,
        lang,
        lesson: lessonId,
      }).unwrap();
      onSuccess(data);
    } catch (e) {
      onError(e);
      message.error('Ошибка при загрузке файла');
    }
  }, [createLessonDocument, lessonId, uploadFile]);

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        label="Изображение урока"
        name="picturePath"
        getValueFromEvent={normFile}
        required
      >
        <CustomUpload
          maxCount={1}
          multiple={false}
          group="lesson_photo"
          existedFileUrl={initialValues.pictureUrl}
        />
      </Form.Item>

      <Form.Item
        name="sysName"
        label="Системное название"
        required
        rules={[
          { required: true, message: 'Системное название обязательно' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="order"
        label="Порядковый номер"
        required
        rules={[
          { required: true, message: 'Порядковый номер обязателен' }
        ]}
      >
        <InputNumber precision={0} />
      </Form.Item>
      <Form.Item
        name="isFree"
        valuePropName="checked"
      >
        <Checkbox>Бесплатный урок?</Checkbox>
      </Form.Item>
      <Form.Item label="Видео из kinescope.ru (Заполните только в том случае, если это видео должно показываться независимо от выбранного пользователем языка)" name="lessonVideoId">
        <Select
          showSearch
          placeholder="Выберите видео"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          loading={isVideosLoading || isVideosFetching}
          options={videoHostingDictionaryToSelect(videos?.data)}
        />
      </Form.Item>

      <Form.Item label="Предмет" name="subject">
        <Select
          showSearch
          placeholder="Выберите предмет урока"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isSubjectsLoading || isSubjectsFetching}
          options={dictionaryToSelect(subjects?.results)}
        />
      </Form.Item>
      <Form.Item label="Раздел" name="chapter">
        <Select
          showSearch
          placeholder="Выберите раздел"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isChaptersLoading || isChaptersFetching}
          options={dictionaryToSelect(chapters?.results)}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col md={24}>
          <Title level={4}>Переводимые поля</Title>
        </Col>
        <Col md={8}>
          {/*   ================     RUSSIAN FIELDS     ================= */}
          <Title level={5}>Русский</Title>
          <Form.Item hidden name={['ru', 'language']} />
          <Form.Item
            name={['ru', 'name']}
            label="Название урока"
            required
            rules={[
              { required: true, message: 'Название обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['ru', 'title']}
            label="Заголовок урока"
            required
            rules={[
              { required: true, message: 'Заголовок обязателен' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['ru', 'description']}
            label="Описание урока"
            required
            rules={[
              { required: true, message: 'Описание обязательно (отображается в списке уроков)' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['ru', 'content']}
            label="Полное описание урока (отображается в самом уроке)"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['ru', 'videoKinescopeId']}
            label="Видео из kinescope.ru"
          >
            <Select
              showSearch
              placeholder="Выберите видео"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isVideosLoading || isVideosFetching}
              options={videoHostingDictionaryToSelect(videos?.data)}
            />
          </Form.Item>

        </Col>
        <Col md={8}>
          {/*   ================     UZBEK FIELDS     ================= */}
          <Title level={5}>Узбекский</Title>
          <Form.Item hidden name={['uz', 'language']} />
          <Form.Item
            name={['uz', 'name']}
            label="Название урока"
            required
            rules={[
              { required: true, message: 'Название обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['uz', 'title']}
            label="Заголовок урока"
            required
            rules={[
              { required: true, message: 'Заголовок обязателен' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['uz', 'description']}
            label="Описание урока"
            required
            rules={[
              { required: true, message: 'Описание обязательно (отображается в списке уроков)' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['uz', 'content']}
            label="Полное описание урока (отображается в самом уроке)"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['uz', 'videoKinescopeId']}
            label="Видео из kinescope.ru"
          >
            <Select
              showSearch
              placeholder="Выберите видео"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isVideosLoading || isVideosFetching}
              options={videoHostingDictionaryToSelect(videos?.data)}
            />
          </Form.Item>
        </Col>
        <Col md={8}>
          {/*   ================     ENGLISH FIELDS     ================= */}
          <Title level={5}>Английский</Title>
          <Form.Item hidden name={['en', 'language']} />
          <Form.Item
            name={['en', 'name']}
            label="Название урока"
            required
            rules={[
              { required: true, message: 'Название обязательно' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['en', 'title']}
            label="Заголовок урока"
            required
            rules={[
              { required: true, message: 'Заголовок обязателен' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['en', 'description']}
            label="Описание урока"
            required
            rules={[
              { required: true, message: 'Описание обязательно (отображается в списке уроков)' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['en', 'content']}
            label="Полное описание урока (отображается в самом уроке)"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['en', 'videoKinescopeId']}
            label="Видео из kinescope.ru"
          >
            <Select
              showSearch
              placeholder="Выберите видео"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isVideosLoading || isVideosFetching}
              options={videoHostingDictionaryToSelect(videos?.data)}
            />
          </Form.Item>
        </Col>
      </Row>

      {
        mode === 'edit' && (
          <Row gutter={16}>
            <Col md={24}>
              <Title level={4}>Раздаточные материалы</Title>
            </Col>
            <Col md={8}>
              <Title level={5}>На русском</Title>
              <Upload
                customRequest={customUploadRequest(1)}
                multiple={false}
                fileList={[]}
              >
                <Button icon={<UploadOutlined />} loading={isUploadFileLoading || isUploadFileFetching}>Загрузить</Button>
              </Upload>
              <List
                style={{ marginTop: 16, marginBottom: 16 }}
                dataSource={initialValues.ruFiles}
                renderItem={({ name, id, size, fileUrl }) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        title="Вы уверены что хотите удалить документ?"
                        onConfirm={deleteDocument(id)}
                        okText="Да, удалить"
                        cancelText="Нет, отменить"
                      >
                        <Button
                          type="text"
                          danger
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    ]}
                  >
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      <a href={fileUrl} download target="_blank">{name} ({size} kb)</a>
                    </div>
                  </List.Item>
                )}
              />
            </Col>
            <Col md={8}>
              <Title level={5}>На узбекском</Title>
              <Upload
                customRequest={customUploadRequest(2)}
                multiple={false}
                fileList={[]}
              >
                <Button icon={<UploadOutlined />} loading={isUploadFileLoading || isUploadFileFetching}>Загрузить</Button>
              </Upload>
              <List
                style={{ marginTop: 16, marginBottom: 16 }}
                dataSource={initialValues.uzFiles}
                renderItem={({ name, id, size, fileUrl }) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        title="Вы уверены что хотите удалить документ?"
                        onConfirm={deleteDocument(id)}
                        okText="Да, удалить"
                        cancelText="Нет, отменить"
                      >
                        <Button
                          type="text"
                          danger
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    ]}
                  >
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      <a href={fileUrl} download target="_blank">{name} ({size} kb)</a>
                    </div>
                  </List.Item>
                )}
              />
            </Col>
            <Col md={8}>
              <Title level={5}>На английском</Title>
              <Upload
                customRequest={customUploadRequest(3)}
                multiple={false}
                fileList={[]}
              >
                <Button icon={<UploadOutlined />} loading={isUploadFileLoading || isUploadFileFetching}>Загрузить</Button>
              </Upload>
              <List
                style={{ marginTop: 16, marginBottom: 16 }}
                dataSource={initialValues.enFiles}
                renderItem={({ name, id, size, fileUrl }) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        title="Вы уверены что хотите удалить документ?"
                        onConfirm={deleteDocument(id)}
                        okText="Да, удалить"
                        cancelText="Нет, отменить"
                      >
                        <Button
                          type="text"
                          danger
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    ]}
                  >
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      <a href={fileUrl} download target="_blank">{name} ({size} kb)</a>
                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )
      }

      <Button htmlType="submit" type="primary" block loading={loading}>
        Сохранить
      </Button>
    </Form>
  );
};

export default LessonForm;
