import React from 'react';
import cn from 'classnames';
import { useToggle } from '@uidotdev/usehooks';

import styles from './CommentApproval.module.scss';
import { Button, message, Popconfirm } from 'antd';
import { useApproveCommentMutation } from '../../store/api/comments';

const CommentApproval = ({ id }) => {
  const [open, toggle] = useToggle(false);

  const [
    approveComment,
    {
      isLoading,
      isFetching,
    }
  ] = useApproveCommentMutation();

  const handleConfirm = async () => {
    try {
      await approveComment(id).unwrap();
      toggle(false);
      message.success('Комментарий одобрен');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Popconfirm
      open={open}
      title="Вы уверены?"
      description="Комментарий будет опубликован и видим всем пользователям"
      okText="Да, уверен"
      cancelText="Нет, отменить"
      onConfirm={handleConfirm}
      onCancel={toggle}
      okButtonProps={{
        loading: isLoading || isFetching,
      }}
    >
      <Button
        type="link"
        onClick={toggle}
      >
        Одобрить
      </Button>
    </Popconfirm>
  );
};

export default CommentApproval;
