// import cn from 'classnames';

import { Button, Col, Drawer, Form, Row } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import ROUTES from '../router/ROUTES';
import queryString from 'query-string';
import { mapObjIndexed } from 'ramda';
import dayjs from 'dayjs';

const FiltersDrawer = ({
  width = 400,
  title = 'Фильтры',
  children,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    const prepareValue = mapObjIndexed((value, key) => {
      if (dayjs.isDayjs(value)) {
        return value.toISOString();
      }
      return value;
    }, values);

    navigate({
      pathname: '',
      search: queryString.stringify(prepareValue),
    });
    setOpen(false);
  };

  const handleReset = () => {
    navigate({
      pathname: '',
    });
    setOpen(false);
  };


  return (
    <>
      <Button
        icon={<FilterOutlined />}
        onClick={() => {
          setOpen(true);
        }}
        type="primary"
      />
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        width={width}
        title={title}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
        >
          {children}
          <Row gutter={10}>
            <Col md={12}>
              <Button
                htmlType="reset"
                block
                onClick={handleReset}
              >
                Сбросить
              </Button>
            </Col>
            <Col md={12}>
              <Button
                htmlType="submit"
                type="primary"
                block
              >
                Применить
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default FiltersDrawer;
