import React from 'react';
import cn from 'classnames';

import styles from './SubjectList.module.scss';
import { useNavigate } from 'react-router';
import { useGetLessonSubjectQuery, useGetMetaSubjectsQuery } from '../../store/api/courses';
import useQueryString from '../../hooks/useQueryString';
import queryString from 'query-string';
import ROUTES from '../../router/ROUTES';
import { Skeleton, Table, Tag } from 'antd';
import { find, includes, propEq } from 'ramda';

const SubjectList = () => {
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...params } = useQueryString();

  const {
    data: subjects,
    isLoading: isSubjectsLoading,
    isFetching: isSubjectsFetching,
  } = useGetLessonSubjectQuery({ page, pageSize, ...params });
  const {
    data: metaSubjects,
  } = useGetMetaSubjectsQuery({ pageSize: 999 });

  const columns = [
    {
      title: 'Системное название',
      dataIndex: 'sysName',
      key: 'sysName',
    },
    {
      title: 'В предметной группе',
      dataIndex: 'metaSubject',
      key: 'metaSubject',
      render: (metaSubjectId) => {
        if (!metaSubjects?.results) return null;

        const metaSubject = find(propEq(metaSubjectId, 'id'))(metaSubjects.results);
        if (!metaSubject) return '-';
        return metaSubject.sysName;
      }
    },
    {
      title: 'Порядковый номер',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Состояние',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (
        isActive
          ? <Tag color="green">Активный</Tag>
          : <Tag color="red">Неактивный</Tag>
      )
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  if (isSubjectsLoading) return <Skeleton />
  if (!subjects) return null;

  return (
    <Table
      dataSource={subjects.results}
      columns={columns}
      rowKey="id"
      loading={isSubjectsFetching}
      onRow={(record) => ({
        onClick: () => navigate(ROUTES.lessonSubjectDetails(record.id))
      })}
      pagination={{
        total: subjects?.count,
        current: page,
        locale: { items_per_page: '/ на странице' },
        pageSize,
        showSizeChanger: true,
        showTitle: true,
        pageSizeOptions: [20, 50, 100],
      }}
      onChange={handlePageChange}
    />
  );
};

export default SubjectList;
