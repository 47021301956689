// import cn from 'classnames';

import { Card, DatePicker, Space, Typography } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useGetSubscriptionDashboardQuery, useGetUserDashboardQuery } from '../store/api/dashboard';
import { formatDate } from '../utils';

const SubscriptionStat = () => {
  const [datesEditing, setDatesEditing] = useState(false);

  const [dates, setDates] = useState([dayjs().startOf('month'), dayjs()]);

  const {
    data: stats,
  } = useGetSubscriptionDashboardQuery({
    start: dates[0].format('YYYY-MM-DD'),
    end: dates[1].format('YYYY-MM-DD'),
  });

  return (
    <Card size="small" style={{ height: '100%' }}>
      <Typography.Title level={4}>По подпискам</Typography.Title>
      <div>
        <Typography.Text>
          <Space>
            <div>
              Куплено за период
            </div>
            <div>
              {
                datesEditing
                  ? (
                    <DatePicker.RangePicker
                      value={dates}
                      onChange={(newDates) => {
                        setDates(newDates);
                        setDatesEditing(false)
                      }}
                      format="DD.MM.YYYY"
                    />
                  ) : (
                    <Typography.Text onClick={() => setDatesEditing(true)} type="warning" style={{ cursor: 'pointer' }}>
                      с {formatDate(dates[0])} по {formatDate(dates[1])}
                    </Typography.Text>
                  )
              }
            </div>
          </Space>
        </Typography.Text>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          {stats?.data.onlineSubscriptionInPeriodCount}
        </Typography.Title>
      </div>
    </Card>
  );
};

export default SubscriptionStat;
