import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getFaqList: build.query({
      query: (params) => ({
        url: apiRoutes.faq(),
        params,
      }),
      providesTags: ['FAQ_LIST'],
    }),
    getFaqDetails: build.query({
      query: (id) => ({
        url: apiRoutes.faq(id),
      }),
      providesTags: ['FAQ_DETAILS'],
    }),
    editFaq: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.faq(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['FAQ_DETAILS', 'FAQ_LIST'] }),
    }),
    createFaq: build.mutation({
      query: (body) => ({
        url: apiRoutes.faq(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['FAQ_DETAILS', 'FAQ_LIST'] }),
    }),
  }),
});

export const {
  useGetFaqListQuery,
  useGetFaqDetailsQuery,
  useEditFaqMutation,
  useCreateFaqMutation,
} = mainApi;
