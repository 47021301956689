// import cn from 'classnames';

import { Form, Radio } from 'antd';
import FiltersDrawer from '../components/FiltersDrawer';
import React from 'react';

const CommentFilter = () => {

  return (
    <FiltersDrawer>
      <Form.Item name="state" label="Статус">
        <Radio.Group>
          <Radio.Button value="waiting">Новый</Radio.Button>
          <Radio.Button value="approve">Одобрен</Radio.Button>
          <Radio.Button value="rejected">Отклонен</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </FiltersDrawer>
  );
};

export default CommentFilter;
