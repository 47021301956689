import React from 'react';
import cn from 'classnames';

import styles from './LessonDetails.module.scss';
import LessonForm from '../../containers/LessonForm';
import { useNavigate, useParams } from 'react-router';
import {
  useCreateLessonDocumentMutation,
  useCreateLessonMutation,
  useEditLessonMutation,
  useGetLessonDetailsQuery,
  useGetLessonsQuery
} from '../../store/api/courses';
import { message, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { find, propEq } from 'ramda';
import ROUTES from '../../router/ROUTES';

const LessonDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: lesson,
    isLoading: isLessonLoading,
    isFetching: isLessonFetching,
  } = useGetLessonDetailsQuery(id, { skip: !id || id === 'create' });

  const [
    createLesson,
    {
      isLoading: isCreateLessonLoading,
      isFetching: isCreateLessonFetching,
    },
  ] = useCreateLessonMutation();

  const [
    editLesson,
    {
      isLoading: isEditLessonLoading,
      isFetching: isEditLessonFetching,
    },
  ] = useEditLessonMutation();

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      ruFiles: [],
      uzFiles: [],
      enFiles: [],
      isActive: false,
    };
    if (!lesson) return rootInitialValues;
    const documents = lesson.documents?.reduce((acc, doc) => {
      const fields = [null, 'ruFiles', 'uzFiles', 'enFiles'];
      const currentFieldName = fields[doc.lang];
      return {
        ...acc,
        [currentFieldName]: [...acc[currentFieldName], doc],
      }
    }, {
      ruFiles: [],
      uzFiles: [],
      enFiles: [],
    });

    return {
      ...rootInitialValues,
      ...lesson,
      ...documents,
      chapter: lesson.chapter?.id,
      subject: lesson.subject?.id,
      ru: find(propEq(1, 'language'))(lesson.translates),
      uz: find(propEq(2, 'language'))(lesson.translates),
      en: find(propEq(3, 'language'))(lesson.translates),
    }
  };

  const onCreate = async (values) => {
    try {
      const { ruFiles, enFiles, uzFiles, ...restValues } = values;
      const response = await createLesson(restValues).unwrap();
      navigate(ROUTES.lessonDetails(response.id), { replace: true });

      message.success('Урок создан. Теперь Вы можете добавить в него раздаточный материал');
    } catch (e) {
      console.error(e);
    }
  };

  const onEdit = async (values) => {
    try {
      await editLesson({ body: values, lessonId: id }).unwrap();
      message.success('Урок изменен');
    } catch (e) {
      console.error(e);
    }
  };

  if (isLessonLoading) return <Skeleton />;

  return (
    <div className="main-content">
      <LessonForm
        initialValues={buildInitialValues()}
        onSubmit={id === 'create' ? onCreate : onEdit}
        mode={id === 'create' ? 'create' : 'edit'}
        lessonId={id}
        loading={
          isCreateLessonLoading
          || isCreateLessonFetching
          || isEditLessonLoading
          || isEditLessonFetching
        }
      />
    </div>
  );
};

export default LessonDetails;
