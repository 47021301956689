import React from 'react';
import CommentList from '../../containers/CommentList';
import { Col, Row, Typography } from 'antd';
import CommentFilter from '../../containers/CommentFilter';

const { Title } = Typography;

const Comments = () => {

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Комментарии</Title>
        </Col>
        <Col>
          <CommentFilter />
        </Col>
      </Row>
      <CommentList />
    </div>
  );
};

export default Comments;
