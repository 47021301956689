import React from 'react';
import cn from 'classnames';

import styles from './SubjectTransferForm.module.scss';
import { omit } from 'ramda';
import { Button, Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { crmSubjectDictionaryToSelect, dictionaryToSelect, videoHostingDictionaryToSelect } from '../../utils';
import { useGetLessonSubjectQuery, useGetMetaSubjectsQuery } from '../../store/api/courses';
import { useGetCrmSubjectsQuery } from '../../store/api/subjectTransfer';

const { Title } = Typography;

const SubjectTransferForm = ({
  onSubmit,
  initialValues,
  loading,
}) => {

  const {
    data: metaSubjects,
    isFetching: isMetaSubjectFetching,
  } = useGetMetaSubjectsQuery({ page: -1 });
  const {
    data: subjects,
    isFetching: isSubjectFetching,
  } = useGetLessonSubjectQuery({ page: -1 });
  const {
    data: crmSubjects,
    isFetching: isCrmSubjectFetching,
  } = useGetCrmSubjectsQuery({ page: -1 });

  const onFinish = async (values) => {
    console.log(values);
    // const preparedValues = omit(
    //   ['ru', 'en', 'uz'],
    //   {
    //     ...values,
    //     translates: [values.ru, values.en, values.uz],
    //     alias: 'default',
    //   },
    // );
    await onSubmit(values);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      disabled={loading}
      initialValues={initialValues}
    >
      <Form.Item
        name="metaSubject"
        label="Предметная группа на платформе"
        rules={[
          {
            required: true,
            message: 'Поле обязательно'
          },
        ]}
        required
      >
        <Select
          showSearch
          placeholder="Выберите предметную группу"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          loading={isMetaSubjectFetching}
          options={dictionaryToSelect(metaSubjects?.results)}
        />
      </Form.Item>

      <Form.Item
        name="appSubject"
        label="Курс на платформе"
      >
        <Select
          showSearch
          placeholder="Выберите курс"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          loading={isSubjectFetching}
          options={dictionaryToSelect(subjects?.results)}
        />
      </Form.Item>

      <Form.Item
        name="crmSubject"
        label="Предмет в оффлайн CRM системе"
        rules={[
          {
            required: true,
            message: 'Поле обязательно'
          },
        ]}
        required
      >
        <Select
          showSearch
          placeholder="Выберите предмет"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          loading={isCrmSubjectFetching}
          options={crmSubjectDictionaryToSelect(crmSubjects?.results)}
        />
      </Form.Item>

      <Form.Item
        name="crmLanguage"
        label="Язык предмета в оффлайн CRM системе"
        rules={[
          {
            required: true,
            message: 'Поле обязательно'
          },
        ]}
        required
      >
        <Select
          placeholder="Выберите язык"
          allowClear
          options={[
            {
              value: 'ru',
              label: 'Русский',
            },
            {
              value: 'uz',
              label: 'Узбекский',
            },
            {
              value: 'en',
              label: 'Английский',
            },
          ]}
        />
      </Form.Item>


      <Button htmlType="submit" type="primary" block loading={loading}>
        Сохранить
      </Button>
    </Form>
  );
};

export default SubjectTransferForm;
