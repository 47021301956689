import React from 'react';
import cn from 'classnames';

import styles from './StudentList.module.scss';
import { useGetStudentsQuery } from '../../store/api/students';
import { Skeleton, Table, Tag } from 'antd';
import { formatDate, joinExisting } from '../../utils';
import useQueryString from '../../hooks/useQueryString';
import queryString from 'query-string';
import { useNavigate } from 'react-router';

const StudentList = () => {
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...rest } = useQueryString();

  const {
    data: students,
    isLoading,
    isFetching,
  } = useGetStudentsQuery({ page, pageSize, ...rest });

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'lastName',
      sorter: true,
      render: (data, raw) => joinExisting([
        raw.lastName, raw.firstName, raw.secondName
      ], ' '),
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Учится в центре?',
      dataIndex: 'isOffline',
      key: 'isOffline',
      sorter: true,
      render: (isOffline) => (
        isOffline
          ? <Tag color="green">Да</Tag>
          : <Tag color="red">Нет</Tag>
      )
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'registrationAt',
      key: 'registrationAt',
      sorter: true,
      render: (date) => formatDate(date, true)
    },
  ];

  const handlePageChange = (pagination, filters, sorter) => {
    const {
      columnKey, // key
      column, // object
      field,
      order, // descend, ascend
    } = sorter;

    const sortSymbol = order === 'descend' ? '-' : '';

    const sort = order ? `${sortSymbol}${columnKey}` : null;

    const search = queryString.stringify({
      page: pagination.current,
      pageSize: pagination.pageSize,
      ...rest,
      sort,
    });

    navigate({
      pathname: '',
      search,
    })
  };

  if (isLoading) return <Skeleton />
  if (!students) return null;

  return (
    <>
      <Table
        dataSource={students.results}
        columns={columns}
        rowKey="id"
        locale={{
          triggerDesc: 'Сортировать по убыванию',
          triggerAsc: 'Сортировать по возрастанию',
          cancelSort: 'Отменить сортировку'
        }}
        loading={isFetching}
        pagination={{
          total: students?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onChange={handlePageChange}
      />
    </>
  );
};

export default StudentList;
