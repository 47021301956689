import React from 'react';
import cn from 'classnames';

import styles from './Welcome.module.scss';
import { Card, Col, Row, Typography } from 'antd';
import { useGetUserDashboardQuery } from '../../store/api/dashboard';
import UsersStat from '../../containers/UsersStat';
import SubscriptionStat from '../../containers/SubscriptionStat';
import SubscriptionByTariff from '../../containers/SubscriptionByTariff';
import SubscriptionBySubject from '../../containers/SubscriptionBySubject';

const Welcome = () => {

  return (
    <div className={styles.welcome}>
      <Typography.Title level={2}>Отчет</Typography.Title>

      <Row gutter={12}>
        <Col md={12}>
          <UsersStat />
        </Col>
        <Col md={12}>
          <SubscriptionStat />
        </Col>
      </Row>
      <Row gutter={12} style={{ marginTop: 12 }}>
        <Col md={12}>
          <SubscriptionByTariff />
        </Col>
        <Col md={12}>
          <SubscriptionBySubject />
        </Col>
      </Row>
    </div>
  );
};

export default Welcome;
