import React from 'react';
import cn from 'classnames';

import styles from './SubjectTransfer.module.scss';
import {
  useGetCrmSubjectsQuery,
  useGetSubjectTransferListQuery,
  useLazySyncCrmSubjectsQuery
} from '../../store/api/subjectTransfer';
import { Button, Col, Row, Typography } from 'antd';
import ROUTES from '../../router/ROUTES';
import TransferSubjectList from '../../containers/TransferSubjectList';
import { useNavigate } from 'react-router';
import SubjectTransferSync from '../../containers/SubjectTransferSync';
import SubjectTransferNotes from '../../containers/SubjectTransferNotes';

const { Title } = Typography;

const SubjectTransfer = () => {
  const navigate = useNavigate();
  const {
    data: crmSubjects,
    isFetching: isCrmFetching,
  } = useGetCrmSubjectsQuery({ page: -1 });
  const {
    data: subjectTransfers,
    isFetching: isSubjectTransferFetching,
  } = useGetSubjectTransferListQuery({ page: -1 });

  const [
    syncSubjects,
    {
      isFetching: isSyncing,
    }
  ] = useLazySyncCrmSubjectsQuery();

  const handleSync = async () => {
    await syncSubjects().unwrap();
  }

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>
            Связь предметов платформы с предметами оффнлайн CRM системы
            <SubjectTransferNotes />
          </Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => { navigate(ROUTES.subjectTransferDetails('create')) }}>
            Создать связь
          </Button>
        </Col>
      </Row>
      <SubjectTransferSync />
      <TransferSubjectList />
    </div>
  );
};

export default SubjectTransfer;
