import React from 'react';
import { useToggle } from '@uidotdev/usehooks';
import { useRejectCommentMutation } from '../../store/api/comments';
import { Button, message, Popconfirm } from 'antd';

const CommentRejection = ({ id }) => {
  const [open, toggle] = useToggle(false);

  const [
    rejectComment,
    {
      isLoading,
      isFetching,
    }
  ] = useRejectCommentMutation();

  const handleConfirm = async () => {
    try {
      await rejectComment(id).unwrap();
      toggle(false);
      message.success('Комментарий отклонен');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Popconfirm
      open={open}
      title="Вы уверены?"
      description="Комментарий будет отклонен. Но Вы сможете его одобрить позже"
      okText="Да, уверен"
      cancelText="Нет, отменить"
      onConfirm={handleConfirm}
      onCancel={toggle}
      okButtonProps={{
        loading: isLoading || isFetching,
      }}
    >
      <Button
        type="link"
        danger
        onClick={toggle}
      >
        Отклонить
      </Button>
    </Popconfirm>
  );
};

export default CommentRejection;
