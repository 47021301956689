import React from 'react';
import cn from 'classnames';

import styles from './FAQList.module.scss';
import useQueryString from '../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import { useGetTeachersQuery } from '../../store/api/teachers';
import { formatDate } from '../../utils';
import { Skeleton, Table, Tag } from 'antd';
import queryString from 'query-string';
import ROUTES from '../../router/ROUTES';
import { useGetFaqListQuery } from '../../store/api/faq';

const FAQList = () => {
  const { page = 1, pageSize = 20, ...params } = useQueryString();
  const navigate = useNavigate();

  const {
    data: faq,
    isLoading,
    isFetching,
  } = useGetFaqListQuery({ page, pageSize, ...params });

  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      key: 'order',
      width: 40,
    },
    {
      title: 'Системное название',
      dataIndex: 'sysName',
      key: 'sysName',
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      key: 'alias',
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  if (isLoading) return <Skeleton />;
  if (!faq) return null;

  return (
    <>
      <Table
        dataSource={faq.results}
        columns={columns}
        rowKey="id"
        loading={isFetching}
        onRow={(record) => ({
          onClick: () => navigate(ROUTES.faqDetails(record.id)),
        })}
        pagination={{
          total: faq?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onChange={handlePageChange}
      />
    </>
  );
};

export default FAQList;
