import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTeachers: build.query({
      query: (params) => ({
        url: apiRoutes.teachers(),
        params,
      }),
      providesTags: ['TEACHERS'],
    }),
    getTeacherDetails: build.query({
      query: (teacherId) => ({
        url: apiRoutes.teachers(teacherId),
      }),
      providesTags: ['TEACHER_DETAILS'],
    }),
    createTeacher: build.mutation({
      query: (body) => ({
        url: apiRoutes.teachers(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TEACHERS', 'TEACHER_DETAILS'] }),
    }),
    editTeacher: build.mutation({
      query: ({ body, teacherId }) => ({
        url: apiRoutes.teachers(teacherId),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TEACHERS', 'TEACHER_DETAILS'] }),
    }),
  }),
});

export const {
  useGetTeachersQuery,
  useGetTeacherDetailsQuery,
  useCreateTeacherMutation,
  useEditTeacherMutation,
} = mainApi;
