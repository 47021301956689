import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getLanguages: build.query({
      query: (params) => ({
        url: apiRoutes.languages(),
        params,
      }),
      providesTags: ['LANGUAGES'],
    }),
  }),
});

export const {
  useGetLanguagesQuery,
} = mainApi;
