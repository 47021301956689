import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getAboutServiceSteps: build.query({
      query: (params) => ({
        url: apiRoutes.aboutServiceSteps(),
        params,
      }),
      providesTags: ['ABOUT_SERVICE_STEPS'],
    }),
    getAboutServiceStepDetails: build.query({
      query: (id) => ({
        url: apiRoutes.aboutServiceSteps(id),
      }),
      providesTags: ['ABOUT_SERVICE_STEP_DETAILS'],
    }),
    editAboutServiceStep: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.aboutServiceSteps(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['ABOUT_SERVICE_STEPS', 'ABOUT_SERVICE_STEP_DETAILS'] }),
    }),
    createAboutServiceStep: build.mutation({
      query: (body) => ({
        url: apiRoutes.aboutServiceSteps(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['ABOUT_SERVICE_STEPS', 'ABOUT_SERVICE_STEP_DETAILS'] }),
    }),
  }),
});

export const {
  useGetAboutServiceStepsQuery,
  useGetAboutServiceStepDetailsQuery,
  useEditAboutServiceStepMutation,
  useCreateAboutServiceStepMutation,
} = mainApi;
