export default {
  root: () => '/',

  // Application (Protected)
  app: () => '/app',
  welcome: () => '/app/welcome',
  students: () => '/app/students',
  comments: () => '/app/comments',
  teachers: () => '/app/teachers',
  teacherDetails: (id) => `/app/teachers/${id}`,
  tariffs: () => '/app/tariffs',
  tariffDetails: (id) => `/app/tariffs/${id}`,
  subscriptions: () => '/app/subscription',
  lessonChapters: () => '/app/chapters',
  lessonChapterDetails: (id) => `/app/chapters/${id}`,
  lessonSubjects: () => '/app/subjects',
  metaSubjects: () => '/app/meta-subjects',
  metaSubjectDetails: (id) => `/app/meta-subjects/${id}`,
  lessonSubjectDetails: (id) => `/app/subjects/${id}`,
  lessons: () => `/app/lessons`,
  lessonDetails: (id) => `/app/lessons/${id}`,
  faq: () => `/app/faq`,
  staticData: () => `/app/static-content`,
  staticDataDetails: (id) => `/app/static-content/${id}`,
  faqDetails: (id) => `/app/faq/${id}`,
  aboutServiceSteps: () => `/app/service-steps`,
  aboutServiceStepDetails: (id) => `/app/service-steps/${id}`,
  subjectTransfer: () => `/app/subject-transfers`,
  subjectTransferDetails: (id) => `/app/subject-transfers/${id}`,
};
