import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { mainApi } from './api';
import { rtkQueryErrorLogger } from './handleErrorMiddleware';


const rootReducer = combineReducers({
  [mainApi.reducerPath]: mainApi.reducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
    .concat(rtkQueryErrorLogger)
    .concat(mainApi.middleware),
});
