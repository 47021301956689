import React from 'react';
import { useGetTeachersQuery } from '../../store/api/teachers';
import useQueryString from '../../hooks/useQueryString';
import { Skeleton, Table, Tag } from 'antd';
import { formatDate } from '../../utils';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';

const TeacherList = () => {
  const { page = 1, pageSize = 20, ...params } = useQueryString();
  const navigate = useNavigate();

  const {
    data: teachers,
    isLoading,
    isFetching,
  } = useGetTeachersQuery({ page, pageSize, ...params });

  const columns = [
    {
      title: 'Фото',
      dataIndex: 'pictureUrl',
      key: 'pictureUrl',
      render: (url) => <img src={url} alt="" style={{ width: 60 }} />
    },
    {
      title: 'Системное имя',
      dataIndex: 'sysFio',
      key: 'sysFio',
    },
    {
      title: 'Дата рождения',
      dataIndex: 'dateOfBirthday',
      key: 'dateOfBirthday',
      render: (date) => formatDate(date),
    },
    {
      title: 'Опубликован',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (
        isActive
          ? <Tag color="green">Да</Tag>
          : <Tag color="red">Нет</Tag>
      ),
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  if (isLoading) return <Skeleton />;
  if (!teachers) return null;


  return (
    <>
      <Table
        dataSource={teachers.results}
        columns={columns}
        rowKey="id"
        loading={isFetching}
        onRow={(record) => ({
          onClick: () => navigate(ROUTES.teacherDetails(record.id)),
        })}
        pagination={{
          total: teachers?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onChange={handlePageChange}
      />
    </>
  );
};

export default TeacherList;
