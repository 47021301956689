import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getUserDashboard: build.query({
      query: (params) => ({
        url: apiRoutes.usersDashboard(),
        params,
      }),
      providesTags: ['USERS_DASHBOARD'],
    }),
    getSubscriptionDashboard: build.query({
      query: (params) => ({
        url: apiRoutes.subscriptionDashboard(),
        params,
      }),
      providesTags: ['SUBSCRIPTION_DASHBOARD'],
    }),
    getSubscriptionByTariffDashboard: build.query({
      query: (params) => ({
        url: apiRoutes.subscriptionByTariffDashboard(),
        params,
      }),
      providesTags: ['SUBSCRIPTION_BY_TARIFF_DASHBOARD'],
    }),
    getSubscriptionBySubjectDashboard: build.query({
      query: (params) => ({
        url: apiRoutes.subscriptionBySubjectDashboard(),
        params,
      }),
      providesTags: ['SUBSCRIPTION_BY_SUBJECT_DASHBOARD'],
    }),
  }),
});

export const {
  useGetUserDashboardQuery,
  useGetSubscriptionDashboardQuery,
  useGetSubscriptionByTariffDashboardQuery,
  useGetSubscriptionBySubjectDashboardQuery,
} = mainApi;
