import React from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import { omit } from 'ramda';

const { Title } = Typography;

const TariffForm = ({
  onSubmit,
  initialValues,
  loading,
}) => {
  const onFinish = async (values) => {
    const preparedValues = omit(
      ['ru', 'uz', 'en'],
      {
        ...values,
        translates: [values.ru, values.uz, values.en],
        isUnlimited: values.subjectCount === 0,
      },
    );
    await onSubmit(preparedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      disabled={loading}
      initialValues={initialValues}
    >
      <Form.Item
        name="sysName"
        label="Системное имя (Используется только в админ. панеле)"
        rules={[
          {
            required: true,
            message: 'Системное имя обязательно'
          },
        ]}
        required
      >
        <Input placeholder="Выберите имя по которому сможете быстро определить тариф" />
      </Form.Item>
      <Form.Item
        name="monthCount"
        label="Количество месяцев в тарифе"
        rules={[
          {
            required: true,
            message: 'Обязательное поле'
          },
        ]}
        required
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="subjectCount"
        label="Количество предметов в тарифе (для неограниченного количества поставить 0)"
        rules={[
          {
            required: true,
            message: 'Обязательное поле'
          },
        ]}
        required
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="oldPrice"
        label="Старая цена тарифа (будет зачеркнута)"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="price"
        label="Цена тарифа"
        rules={[
          {
            required: true,
            message: 'Обязательное поле'
          },
        ]}
        required
      >
        <InputNumber />
      </Form.Item>
      <Row gutter={16}>
        <Col md={24}>
          <Title level={4}>Переводимые поля</Title>
        </Col>

        {/* ========================  РУССКИЙ ЯЗЫК =========================*/}
        <Col md={8}>
          <Title level={5}>Русский</Title>
          <Form.Item name={['ru', 'language']} hidden />
          <Form.Item
            name={['ru', 'name']}
            label="Название (публичное)"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф Лучший.." />
          </Form.Item>
          <Form.Item
            name={['ru', 'title']}
            label="Заголовок"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф Лучший.." />
          </Form.Item>
          <Form.Item
            name={['ru', 'description']}
            label="Описание"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф предназначен для студентов ВУЗов..." />
          </Form.Item>
        </Col>

        {/* ========================  UZBEK  LANGUAGE =========================*/}
        <Col md={8}>
          <Title level={5}>Узбекский</Title>
          <Form.Item name={['uz', 'language']} hidden />
          <Form.Item
            name={['uz', 'name']}
            label="Название (публичное)"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф Лучший.." />
          </Form.Item>
          <Form.Item
            name={['uz', 'title']}
            label="Заголовок"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф Лучший.." />
          </Form.Item>
          <Form.Item
            name={['uz', 'description']}
            label="Описание"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф предназначен для студентов ВУЗов..." />
          </Form.Item>
        </Col>

        {/* ========================  ENGLISH  LANGUAGE =========================*/}
        <Col md={8}>
          <Title level={5}>Узбекский</Title>
          <Form.Item name={['en', 'language']} hidden />
          <Form.Item
            name={['en', 'name']}
            label="Название (публичное)"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф Лучший.." />
          </Form.Item>
          <Form.Item
            name={['en', 'title']}
            label="Заголовок"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф Лучший.." />
          </Form.Item>
          <Form.Item
            name={['en', 'description']}
            label="Описание"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Тариф предназначен для студентов ВУЗов..." />
          </Form.Item>
        </Col>
      </Row>
      <Button htmlType="submit" type="primary" block>
        Сохранить
      </Button>
    </Form>
  );
};

export default TariffForm;
