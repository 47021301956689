// import cn from 'classnames';

import { Input, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDebounce } from "@uidotdev/usehooks";
import { useNavigate } from 'react-router';
import queryString from 'query-string';

const SysNameFilter = ({
  fieldName = 'sysName',
}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const debouncedSearchTerm = useDebounce(filter, 300);

  useEffect(() => {
    const searchHN = () => {
      navigate({
        pathname: '',
        search: queryString.stringify({ [fieldName]: debouncedSearchTerm }),
      });
    };

    searchHN();
  }, [debouncedSearchTerm, navigate]);

  return (
    <Space>
      <Typography.Text>Поиск по системному имени</Typography.Text>
      <Input
        value={filter}
        onChange={e => setFilter(e.target.value)}
        placeholder="Начните вводить системное имя"
        style={{ width: '320px' }}
      />
    </Space>
  );
};

export default SysNameFilter;
