import React from 'react';
import cn from 'classnames';

import styles from './MetaSubjectList.module.scss';
import { useGetMetaSubjectsQuery } from '../../store/api/courses';
import { Skeleton, Table, Tag } from 'antd';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';
import useQueryString from '../../hooks/useQueryString';

const MetaSubjectList = () => {
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...params } = useQueryString();

  const {
    data: metaSubjects,
    isLoading: isMetaSubjectsLoading,
    isFetching: isMetaSubjectsFetching,
  } = useGetMetaSubjectsQuery({
    page, pageSize, ...params,
  });

  const columns = [
    {
      title: 'Изображение',
      dataIndex: 'pictureUrl',
      key: 'pictureUrl',
      render: (image) => <img src={image} alt="" style={{ width: 140 }} />
    },
    {
      title: 'Системное название',
      dataIndex: 'sysName',
      key: 'sysName',
    },
    {
      title: 'Порядковый номер',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'ID в offline CRM',
      dataIndex: 'crmCode',
      key: 'crmCode',
    },
    {
      title: 'Статус',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => isActive
        ? <Tag color="green">Активный</Tag>
        : <Tag color="red">Неактивный</Tag>
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  if (isMetaSubjectsLoading) return <Skeleton />
  if (!metaSubjects) return null;

  return (
    <div>
      <Table
        dataSource={metaSubjects.results}
        columns={columns}
        rowKey="id"
        loading={isMetaSubjectsFetching}
        onRow={(record) => ({
          onClick: () => navigate(ROUTES.metaSubjectDetails(record.id))
        })}
        pagination={{
          total: metaSubjects?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default MetaSubjectList;
