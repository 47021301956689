import React from 'react';
import { Button, Card, Form, Input, message } from 'antd';

import styles from './Entry.module.scss';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { useLoginMutation } from '../../store/api/entry';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';

const Entry = () => {
  const navigate = useNavigate();

  const [
    login,
    {
      isLoading,
      isFetching,
    },
  ] = useLoginMutation();

  const onFinish = async (values) => {
    try {
      const response = await login(values).unwrap();
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      navigate(ROUTES.welcome(), { replace: true });
      message.success('Добро пожаловать');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Card style={{ width: '30%' }}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          disabled={isLoading || isFetching}
        >
          <Form.Item
            label="Логин"
            name="username"
            rules={[
              {
                required: true,
                message: 'Пожалуйста введите имя',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Пожалуйста введите пароль',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button type="primary" htmlType="submit" block loading={isLoading || isFetching}>
            Войти
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Entry;
