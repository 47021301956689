
import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getVideoList: build.query({
      query: (params) => ({
        url: apiRoutes.videoList(),
        params,
      }),
    }),
  }),
});

export const {
  useGetVideoListQuery,
} = mainApi;
