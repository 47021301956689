import React from 'react';
import cn from 'classnames';

import styles from './MetaSubjects.module.scss';
import { useNavigate } from 'react-router';
import { Button, Col, Row, Typography } from 'antd';
import ROUTES from '../../router/ROUTES';
import SubjectList from '../../containers/SubjectList';
import MetaSubjectList from '../../containers/MetaSubjectList';
import SysNameFilter from '../../components/SysNameFilter';

const { Title } = Typography;

const MetaSubjects = () => {
  const navigate = useNavigate();

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Предметная группа</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(ROUTES.metaSubjectDetails('create'))}>
            Создать
          </Button>
        </Col>
      </Row>
      <div style={{ marginBottom: 12 }}>
        <SysNameFilter/>
      </div>
      <MetaSubjectList/>
    </div>
  );
};

export default MetaSubjects;
