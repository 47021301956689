import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getStudents: build.query({
      query: (params) => ({
        url: apiRoutes.students(),
        params,
      }),
      providesTags: ['STUDENTS'],
    }),
  }),
});

export const {
  useGetStudentsQuery,
  useLazyGetStudentsQuery,
} = mainApi;
