import { createBrowserRouter } from 'react-router-dom';
import ROUTES from './ROUTES';
import Entry from '../pages/Entry';
import ProtectRoute from './ProtectRoute';
import AppLayout from '../layout/AppLayout';
import Welcome from '../pages/Welcome';
import Students from '../pages/Students';
import Comments from '../pages/Comments';
import Teachers from '../pages/Teachers';
import TeacherDetails from '../pages/TeacherDetails';
import Tariffs from '../pages/Tariffs';
import TariffDetails from '../pages/TariffDetails';
import Subscriptions from '../pages/Subscriptions';
import Chapters from '../pages/Chapters';
import ChapterDetails from '../pages/ChapterDetails';
import Subjects from '../pages/Subjects';
import SubjectDetails from '../pages/SubjectDetails';
import Lessons from '../pages/Lessons';
import LessonDetails from '../pages/LessonDetails';
import MetaSubjects from '../pages/MetaSubjects';
import MetaSubjectDetails from '../pages/MetaSubjectDetails';
import FAQ from '../pages/FAQ';
import FAQDetails from '../pages/FAQDetails';
import AboutServiceSteps from '../pages/AboutServiceSteps';
import AboutServiceStepDetails from '../pages/AboutServiceStepDetails';
import AboutServiceYoutubeLink from '../pages/AboutServiceYoutubeLink';
import SubjectTransfer from '../pages/SubjectTransfer';
import SubjectTransferDetails from '../pages/SubjectTransferDetails';

export default createBrowserRouter([
  {
    path: ROUTES.root(),
    element: <Entry />,
    errorElement: <div>ERROR</div>
  },
  {
    path: ROUTES.app(),
    element: <ProtectRoute><AppLayout/></ProtectRoute>,
    errorElement: <AppLayout />,
    children: [
      {
        path: ROUTES.welcome(),
        element: <Welcome />,
      },
      {
        path: ROUTES.students(),
        element: <Students />,
      },
      {
        path: ROUTES.comments(),
        element: <Comments />,
      },
      {
        path: ROUTES.teachers(),
        element: <Teachers />,
      },
      {
        path: ROUTES.teacherDetails(':id'),
        element: <TeacherDetails />,
      },
      {
        path: ROUTES.tariffs(),
        element: <Tariffs />,
      },
      {
        path: ROUTES.tariffDetails(':id'),
        element: <TariffDetails />,
      },
      {
        path: ROUTES.tariffDetails(':id'),
        element: <TariffDetails />,
      },
      {
        path: ROUTES.subscriptions(),
        element: <Subscriptions />,
      },
      {
        path: ROUTES.lessonChapters(),
        element: <Chapters />,
      },
      {
        path: ROUTES.lessonChapterDetails(':id'),
        element: <ChapterDetails />,
      },
      {
        path: ROUTES.lessonSubjects(),
        element: <Subjects />,
      },
      {
        path: ROUTES.lessonSubjectDetails(':id'),
        element: <SubjectDetails />,
      },
      {
        path: ROUTES.lessons(),
        element: <Lessons />,
      },
      {
        path: ROUTES.lessonDetails(':id'),
        element: <LessonDetails />,
      },
      {
        path: ROUTES.metaSubjects(),
        element: <MetaSubjects />,
      },
      {
        path: ROUTES.metaSubjectDetails(':id'),
        element: <MetaSubjectDetails />,
      },
      {
        path: ROUTES.faq(),
        element: <FAQ />,
      },
      {
        path: ROUTES.faqDetails(':id'),
        element: <FAQDetails />,
      },
      {
        path: ROUTES.aboutServiceSteps(),
        element: <AboutServiceSteps />,
      },
      {
        path: ROUTES.aboutServiceStepDetails(':id'),
        element: <AboutServiceStepDetails />,
      },
      {
        path: ROUTES.staticData(),
        element: <AboutServiceYoutubeLink />,
      },
      {
        path: ROUTES.subjectTransfer(),
        element: <SubjectTransfer />,
      },
      {
        path: ROUTES.subjectTransferDetails(':id'),
        element: <SubjectTransferDetails />,
      },
    ],
  }
]);
