import React from 'react';
import { Button, Col, Form, Input, InputNumber, message, Row, Skeleton, Typography } from 'antd';
import { useEditStaticContentMutation, useGetStaticContentQuery } from '../../store/api/staticContents';
import { find, omit, propEq } from 'ramda';

const { Title } = Typography;

const AboutServiceYoutubeLink = () => {
  const {
    data: link,
    isFetching,
  } = useGetStaticContentQuery(1);
  const [editLink, { isLoading }] = useEditStaticContentMutation();

  const onFinish = async (values) => {
    const preparedValues = omit(
      ['ru', 'en', 'uz'],
      {
        ...values,
        translates: [values.ru, values.en, values.uz],
        alias: 'default',
        scope: 'about-service-youtube-link',
        fieldName: 'link',
      },
    );
    try {
      await editLink({ id: 1, body: preparedValues }).unwrap();
      message.success('Сохранено');
    } catch (e) {

    }
  };

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
    };
    if (!link) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...link,
      ru: find(propEq(1, 'language'))(link.translates),
      uz: find(propEq(2, 'language'))(link.translates),
      en: find(propEq(3, 'language'))(link.translates),
    }
  };

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Ссылка на YouTube (О сервисе)</Title>
        </Col>
      </Row>
      {
        isFetching
          ? <Skeleton />
          : (
            <Form
              onFinish={onFinish}
              layout="vertical"
              disabled={isLoading}
              initialValues={buildInitialValues()}
            >
              <Form.Item hidden name="scope" />
              <Form.Item hidden name="fieldName" />

              <Row gutter={16}>
                <Col md={24}>
                  <Title level={4}>Переводимые поля</Title>
                </Col>
                <Col md={8}>
                  <Title level={5}>Русский</Title>
                  <Form.Item hidden name={['ru', 'language']} />
                  <Form.Item
                    name={['ru', 'value']}
                    label="Ссылка"
                    rules={[
                      {
                        required: true,
                        message: 'Обязательное поле'
                      },
                    ]}
                    required
                  >
                    <Input placeholder="https://youtube.com/..." />
                  </Form.Item>
                </Col>

                <Col md={8}>
                  <Title level={5}>Узбекский</Title>
                  <Form.Item hidden name={['uz', 'language']} />
                  <Form.Item
                    name={['uz', 'value']}
                    label="Ссылка"
                    rules={[
                      {
                        required: true,
                        message: 'Обязательное поле'
                      },
                    ]}
                    required
                  >
                    <Input placeholder="https://youtube.com/..." />
                  </Form.Item>
                </Col>

                <Col md={8}>
                  <Title level={5}>Английский</Title>
                  <Form.Item hidden name={['en', 'language']} />
                  <Form.Item
                    name={['en', 'value']}
                    label="Ссылка"
                    rules={[
                      {
                        required: true,
                        message: 'Обязательное поле'
                      },
                    ]}
                    required
                  >
                    <Input placeholder="https://youtube.com/..." />
                  </Form.Item>
                </Col>
              </Row>
              <Button htmlType="submit" type="primary" block loading={isLoading}>
                Сохранить
              </Button>
            </Form>
          )
      }
    </div>
  );
};

export default AboutServiceYoutubeLink;
