import React, { useCallback } from 'react';
import { message, Upload } from 'antd';
import styles from './CustomUpload.module.scss';
import { useUploadMutation } from '../../store/api/files';

const CustomUpload = ({
  children,
  value,
  group,
  multiple,
  existedFileUrl,
  ...rest
}) => {
  const [uploadFile] = useUploadMutation();
  const customUploadRequest = useCallback(async ({ onSuccess, onError, onProgress, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group', group);

    try {
      const data = await uploadFile(formData).unwrap();
      message.success('Файл загружен');
      onSuccess(data);
    } catch (e) {
      onError(e);
      message.error('Ошибка при загрузке файла');
    }
  }, [group, uploadFile]);

  return (
    <Upload.Dragger
      {...rest}
      customRequest={customUploadRequest}
      multiple={multiple}
    >
      {
        children || (
          <div>
            <div className={styles.uploadIcon}>
              {existedFileUrl ? <img src={existedFileUrl} alt="" className={styles.uploadExistedImage} /> : '📥'}
            </div>
            <div className={styles.uploadText}>Нажмите или перетащите файлы сюда для загрузки</div>
          </div>
        )
      }
    </Upload.Dragger>
  );
};

export default CustomUpload;
