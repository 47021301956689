import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';
import { invalidateOn } from '../helpers';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getLessonChapters: build.query({
      query: (params) => ({
        url: apiRoutes.lessonChapters(),
        params,
      }),
      providesTags: ['LESSON_CHAPTERS'],
    }),
    getLessonChapterDetails: build.query({
      query: (chapterId) => ({
        url: apiRoutes.lessonChapters(chapterId),
      }),
      providesTags: ['LESSON_CHAPTER_DETAILS'],
    }),
    createLessonChapter: build.mutation({
      query: (body) => ({
        url: apiRoutes.lessonChapters(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_CHAPTERS'] })
    }),
    editLessonChapter: build.mutation({
      query: ({ body, chapterId }) => ({
        url: apiRoutes.lessonChapters(chapterId),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_CHAPTERS', 'LESSON_CHAPTER_DETAILS'] })
    }),

    getLessonSubject: build.query({
      query: (params) => ({
        url: apiRoutes.lessonSubjects(),
        params,
      }),
      providesTags: ['LESSON_SUBJECTS'],
    }),
    getLessonSubjectDetails: build.query({
      query: (subjectId) => ({
        url: apiRoutes.lessonSubjects(subjectId),
      }),
      providesTags: ['LESSON_SUBJECT_DETAILS'],
    }),
    createLessonSubject: build.mutation({
      query: (body) => ({
        url: apiRoutes.lessonSubjects(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_SUBJECTS', 'LESSON_SUBJECT_DETAILS'] })
    }),
    editLessonSubject: build.mutation({
      query: ({ body, subjectId }) => ({
        url: apiRoutes.lessonSubjects(subjectId),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_SUBJECTS', 'LESSON_SUBJECT_DETAILS'] })
    }),

    getLessons: build.query({
      query: (params) => ({
        url: apiRoutes.lessons(),
        params,
      }),
      providesTags: ['LESSONS'],
    }),
    getLessonDetails: build.query({
      query: (lessonId) => ({
        url: apiRoutes.lessons(lessonId),
      }),
      providesTags: ['LESSON_DETAILS'],
    }),
    createLesson: build.mutation({
      query: (body) => ({
        url: apiRoutes.lessons(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSONS'] })
    }),
    editLesson: build.mutation({
      query: ({ body, lessonId }) => ({
        url: apiRoutes.lessons(lessonId),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSONS', 'LESSON_DETAILS'] })
    }),
    createLessonDocument: build.mutation({
      query: (body) => ({
        url: apiRoutes.lessonDocuments(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_DETAILS'] }),
    }),
    deleteLessonDocument: build.mutation({
      query: (documentId) => ({
        url: apiRoutes.lessonDocuments(documentId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_DETAILS'] }),
    }),

    getMetaSubjects: build.query({
      query: (params) => ({
        url: apiRoutes.metaSubjects(),
        params,
      }),
      providesTags: ['META_SUBJECTS'],
    }),
    getMetaSubjectDetails: build.query({
      query: (id) => ({
        url: apiRoutes.metaSubjects(id),
      }),
      providesTags: ['META_SUBJECT_DETAILS'],
    }),
    editMetaSubject: build.mutation({
      query: ({ body, subjectId }) => ({
        url: apiRoutes.metaSubjects(subjectId),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['META_SUBJECTS', 'META_SUBJECT_DETAILS'] })
    }),
    createMetaSubject: build.mutation({
      query: (body) => ({
        url: apiRoutes.metaSubjects(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['META_SUBJECTS'] })
    }),
  }),
});

export const {
  useGetLessonChaptersQuery,
  useGetLessonChapterDetailsQuery,
  useCreateLessonChapterMutation,
  useEditLessonChapterMutation,

  useGetLessonSubjectQuery,
  useLazyGetLessonSubjectQuery,
  useGetLessonSubjectDetailsQuery,
  useCreateLessonSubjectMutation,
  useEditLessonSubjectMutation,

  useGetLessonsQuery,
  useGetLessonDetailsQuery,
  useCreateLessonMutation,
  useEditLessonMutation,
  useCreateLessonDocumentMutation,
  useDeleteLessonDocumentMutation,

  useGetMetaSubjectsQuery,
  useGetMetaSubjectDetailsQuery,
  useEditMetaSubjectMutation,
  useCreateMetaSubjectMutation,
} = mainApi;
