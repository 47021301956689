import React from 'react';
import cn from 'classnames';

import styles from './AboutServiceStepDetails.module.scss';
import { useNavigate, useParams } from 'react-router';
import { useCreateFaqMutation, useEditFaqMutation, useGetFaqDetailsQuery } from '../../store/api/faq';
import { find, propEq } from 'ramda';
import { message, Skeleton, Typography } from 'antd';
import ROUTES from '../../router/ROUTES';
import FAQForm from '../../containers/FAQForm';
import {
  useCreateAboutServiceStepMutation,
  useEditAboutServiceStepMutation,
  useGetAboutServiceStepDetailsQuery
} from '../../store/api/aboutServiceSteps';
import AboutServiceStepForm from '../../containers/AboutServiceStepForm';

const { Title } = Typography;

const AboutServiceStepDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: step,
    isFetching: isStepFetching,
  } = useGetAboutServiceStepDetailsQuery(id, { skip: !id || id === 'create' });

  const [editStep, { isLoading: isEditing }] = useEditAboutServiceStepMutation();
  const [createStep, { isLoading: isCreating }] = useCreateAboutServiceStepMutation();

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      isActive: false,
    };
    if (!step) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...step,
      ru: find(propEq(1, 'language'))(step.translates),
      uz: find(propEq(2, 'language'))(step.translates),
      en: find(propEq(3, 'language'))(step.translates),
    }
  };

  const handleEdit = async (values) => {
    try {
      await editStep({ id, body: values }).unwrap();
      message.success('Шаг отредактирован');
    } catch (e) {

    }
  };

  const handleCreate = async (values) => {
    try {
      const response = await createStep(values).unwrap();
      message.success('Шаг создан');
      navigate(ROUTES.aboutServiceStepDetails(response.id));
    } catch (e) {

    }
  };

  return (
    <div className="main-content">
      <Title level={3}>
        {
          id === 'create'
            ? 'Создать FAQ'
            : 'Детали FAQ'
        }
      </Title>
      {
        id === 'create'
          ? (
            <AboutServiceStepForm
              initialValues={buildInitialValues()}
              onSubmit={handleCreate}
              loading={isCreating}
            />
          ) : (
            (isStepFetching || !step)
              ? <Skeleton />
              : (
                <AboutServiceStepForm
                  initialValues={buildInitialValues()}
                  loading={isStepFetching || isEditing}
                  onSubmit={handleEdit}
                />
              )
          )
      }
    </div>
  );
};

export default AboutServiceStepDetails;
