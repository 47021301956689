import React from 'react';
import cn from 'classnames';

import styles from './AboutServiceSteps.module.scss';
import { Button, Col, Row, Typography } from 'antd';
import ROUTES from '../../router/ROUTES';
import { useNavigate } from 'react-router';
import AboutServiceStepList from '../../containers/AboutServiceStepList';

const { Title } = Typography;

const AboutServiceSteps = () => {
  const navigate = useNavigate();

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Шаги для раздела "О сервисе"</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(ROUTES.aboutServiceStepDetails('create'))}>
            Создать Шаг
          </Button>
        </Col>
      </Row>
      <AboutServiceStepList />
    </div>
  );
};

export default AboutServiceSteps;
