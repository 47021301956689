// import cn from 'classnames';

import { useGetUserDashboardQuery } from '../store/api/dashboard';
import React, { useState } from 'react';
import { Card, DatePicker, Space, Typography } from 'antd';
import { formatDate } from '../utils';
import dayjs from 'dayjs';

const UsersStat = () => {
  const [datesEditing, setDatesEditing] = useState(false);

  const [dates, setDates] = useState([dayjs().startOf('month'), dayjs()]);

  const {
    data: stats,
  } = useGetUserDashboardQuery({
    start: dates[0].format('YYYY-MM-DD'),
    end: dates[1].format('YYYY-MM-DD'),
  });

  return (
    <Card size="small" style={{ height: '100%' }}>
      <Typography.Title level={4}>По пользователям</Typography.Title>
      <div>
        <Typography.Text>
          <Space>
            <div>
              Зарегистрировано за период
            </div>
            <div>
              {
                datesEditing
                  ? (
                    <DatePicker.RangePicker
                      value={dates}
                      onChange={(newDates) => {
                        setDates(newDates);
                        setDatesEditing(false)
                      }}
                      format="DD.MM.YYYY"
                    />
                  ) : (
                    <Typography.Text onClick={() => setDatesEditing(true)} type="warning" style={{ cursor: 'pointer' }}>
                      с {formatDate(dates[0])} по {formatDate(dates[1])}
                    </Typography.Text>
                  )
              }
            </div>
          </Space>
        </Typography.Text>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          {stats?.data.registryInPeriodCount}
        </Typography.Title>
      </div>
      <Space size="large">
        <div>
          <Typography.Text>
            С активной подпиской
          </Typography.Text>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            {stats?.data.hasSubscriptionCount}
          </Typography.Title>
        </div>
        <div>
          <Typography.Text>
            С оффлайн подпиской
          </Typography.Text>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            {stats?.data.hasOfflineSubscriptionCount}
          </Typography.Title>
        </div>
        <div>
          <Typography.Text>
            С онлайн подпиской
          </Typography.Text>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            {stats?.data.hasOnlineSubscriptionCount}
          </Typography.Title>
        </div>
      </Space>
    </Card>
  );
};

export default UsersStat;
