import React from 'react';
import cn from 'classnames';

import styles from './Lessons.module.scss';
import { Button, Col, Row, Typography } from 'antd';
import ROUTES from '../../router/ROUTES';
import SubjectList from '../../containers/SubjectList';
import { useNavigate } from 'react-router';
import LessonList from '../../containers/LessonList';

const { Title } = Typography;

const Lessons = () => {
  const navigate = useNavigate();

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Уроки</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(ROUTES.lessonDetails('create'))}>
            Создать
          </Button>
        </Col>
      </Row>
      <LessonList />
    </div>
  );
};

export default Lessons;
