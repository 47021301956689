import React from 'react';
import cn from 'classnames';

import styles from './Teachers.module.scss';
import { Button, Col, Row, Typography } from 'antd';
import TeacherList from '../../containers/TeacherList';
import { useNavigate } from 'react-router';
import ROUTES from '../../router/ROUTES';
import SysNameFilter from '../../components/SysNameFilter';

const { Title } = Typography;

const Teachers = () => {
  const navigate = useNavigate();

  return (
    <div className="main-content">
      <Row justify="space-between">
        <Col>
          <Title level={3}>Учителя</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(ROUTES.teacherDetails('create'))}>
            Создать учителя
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Col>
          <SysNameFilter fieldName="sysFio" />
        </Col>
      </Row>
      <TeacherList />
    </div>
  );
};

export default Teachers;
