import { mainApi } from './index';
import { apiRoutes } from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: apiRoutes.login(),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
} = mainApi;
