import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useCreateFaqMutation, useEditFaqMutation, useGetFaqDetailsQuery } from '../../store/api/faq';
import { message, Skeleton, Typography } from 'antd';
import FAQForm from '../../containers/FAQForm';
import { find, propEq } from 'ramda';
import ROUTES from '../../router/ROUTES';

const { Title } = Typography;

const FAQDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: faq,
    isFetching: isFaqFetching,
  } = useGetFaqDetailsQuery(id, { skip: !id || id === 'create' });

  const [editFaq, { isLoading: isEditing }] = useEditFaqMutation();
  const [createFaq, { isLoading: isCreating }] = useCreateFaqMutation();

  const buildInitialValues = () => {
    const rootInitialValues = {
      ru: { language: 1 },
      uz: { language: 2 },
      en: { language: 3 },
      isActive: false,
    };
    if (!faq) return rootInitialValues;

    return {
      ...rootInitialValues,
      ...faq,
      ru: find(propEq(1, 'language'))(faq.translates),
      uz: find(propEq(2, 'language'))(faq.translates),
      en: find(propEq(3, 'language'))(faq.translates),
    }
  };

  const handleEdit = async (values) => {
    try {
      await editFaq({ id, body: values }).unwrap();
      message.success('FAQ отредактирован');
    } catch (e) {

    }
  };

  const handleCreate = async (values) => {
    try {
      const response = await createFaq(values).unwrap();
      message.success('FAQ создан');
      navigate(ROUTES.faqDetails(response.id));
    } catch (e) {

    }
  };

  return (
    <div className="main-content">
      <Title level={3}>
        {
          id === 'create'
            ? 'Создать FAQ'
            : 'Детали FAQ'
        }
      </Title>
      {
        id === 'create'
          ? (
            <FAQForm
              initialValues={buildInitialValues()}
              onSubmit={handleCreate}
              loading={isCreating}
            />
          ) : (
            (isFaqFetching || !faq)
              ? <Skeleton />
              : (
                <FAQForm
                  initialValues={buildInitialValues()}
                  loading={isFaqFetching || isEditing}
                  onSubmit={handleEdit}
                />
              )
          )
      }
    </div>
  );
};

export default FAQDetails;
